<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">分類管理</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">新增分類</a></el-breadcrumb-item>
      <!-- <el-breadcrumb-item>商品分类</el-breadcrumb-item> -->

    </el-breadcrumb>
    <el-card>
      <div class="centerBox">
        <el-row class="centerForm">
          <el-form :model="ruleForm"
                   :rules="rules"
                   ref="ruleForm"
                   label-width="110px"
                   class="demo-ruleForm">
            <el-form-item label="分類名稱"
                          prop="name">
              <el-row>
                <el-col :span="24">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="所屬分類"
                          prop="type">
              <el-radio-group v-model="ruleForm.type"
                              @input="changeClassifyType"
                              :disabled="classifyId">
                <el-radio :label="1">一級分類</el-radio>
                <el-radio :label="2">二級分類</el-radio>
                <el-radio :label="3">三級分類</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="分類排序" prop="orderNo">
              <el-row>
                <el-col :span="6">
                  <el-input-number v-model="ruleForm.orderNo" :min="0" size="mini"></el-input-number>
                </el-col>
              </el-row>
            </el-form-item> -->
            <section v-if="classifyType == 1">
              <el-form-item label="上傳文件圖標"
                            prop="iconUrl">
                <el-upload ref="upload"
                           action="https://upload-z2.qiniup.com"
                           :auto-upload="true"
                           class="upload-demo"
                           :headers="spaceData"
                           :on-error="handleError"
                           :on-progress="fileProgress"
                           :on-success="handleSuccess"
                           :on-change="handleChange"
                           :show-file-list="true"
                           :http-request="customerUpload"
                           :file-list="fileList"
                           :before-upload="beforeUpload">
                  <el-button size="small"
                             type=""><i class="el-icon-upload el-icon--right"></i> 上傳文件</el-button>
                  <div slot="file"
                       slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail"
                         :src="imgBaseUrl"
                         alt="">
                  </div>
                  <div slot="tip"
                       class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
                </el-upload>
                <el-image v-if="!filePath && classifyId"
                          :src="ruleForm.iconUrl"></el-image>
              </el-form-item>
            </section>
            <section v-else-if="classifyType == 2 || classifyType == 3">
              <el-form-item label="選擇一級分類"
                            prop="oneId">
                <el-select v-model="ruleForm.oneId"
                           placeholder="请选择"
                           @change="getClassifySelectList(2)">
                  <el-option v-for="item in oneOptions"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </section>
            <section v-if="classifyType == 3">
              <el-form-item label="選擇二級分類"
                            prop="twoId">
                <el-select v-model="ruleForm.twoId"
                           placeholder="请选择"
                           :disabled="selectParentId ? false : true">
                  <el-option v-for="item in twoOptions"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </section>
          </el-form>
        </el-row>
        <div>
          <el-button v-if="classifyId"
                     @click="delClassify()">刪除分類</el-button>
          <el-button @click="resetForm()">取消</el-button>
          <el-button type="danger"
                     @click="submitForm('ruleForm')">保存</el-button>

        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { classifyAddInfo, classifyEditInfo, classifySelectList, classifyEditDel, fileUpload } from '@/config/index'
import * as qiniu from 'qiniu-js'
import axios from 'axios'
import { baseUrl, $ENV } from '@/.env'

export default {
  data () {
    return {
      classifyId: null,
      classifyType: 1,
      ruleForm: {
        name: '',
        type: 1,
        iconUrl: '',
        oneId: '',
        oneName: '',
        twoId: '',
        twoName: '',
        orderNo: 0

      },
      rules: {
        name: [
          { required: true, message: '請輸入分類名稱', trigger: 'blur' },
          { min: 3, max: 12, message: '长度在 3 到 12 个字符', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '請選擇所屬分類', trigger: 'change' }
        ],
        iconUrl: [
          { required: false, message: '請上傳分類圖片', trigger: 'change' }
        ]
      },
      fileList: [],
      oneOptions: [],
      twoOptions: [],
      spaceData: {
        mime: ['image/jpeg', 'image/png', 'image/jpg'], // 后台限制的文件格式
        space: '', // 存储空间标识(七牛云or阿里云)
        domain: 'https://img-cdn.dakuu.cn', // 存储域名
        token: '',
        unique_key: '',
        region: '30', // 存储区域
        max: 5242880
      },
      uploadFileList: [], // 上传文件列表  {name,size,percentage,status,uid}  uid唯一标识
      attachment: [], // 已上传文件列表 与父组件的数据对应
      filePath: '',
      selectParentId: null,
      imgBaseUrl: ''
    }
  },
  computed: {
    qiniuDomain () {
      return function (region) {
        switch (region) {
          case '10':
            return 'https://upload.qiniup.com'
          case '20':
            return 'https://upload-z1.qiniup.com'
          case '30':
            return 'https://upload-z2.qiniup.com'
          case '40':
            return 'https://upload-na0.qiniup.com'
          case '50':
            return 'https://upload-as0.qiniup.com'
          default:
            return 'https://upload-z1.qiniup.com'
        }
      }
    }
  },
  watch: {
    classifyType () {
      this.$set(this.rules.iconUrl[0], 'required', this.classifyType == 1)
    }
  },
  created () {
    this.getSpaceMethod()
    this.getClassifySelectList(1)
  },
  async mounted () {
    const { classifyType, row } = this.$route.query
    if (classifyType) {
      this.classifyType = classifyType == 'first' ? 1 : classifyType == 'second' ? 2 : 3
      this.$set(this.ruleForm, 'type', this.classifyType)
    }
    if (this.classifyType == 1) {
      this.$set(this.rules.iconUrl[0], 'required', true)
    }

    console.log('row', row)
    if (row?.id) {
      this.classifyId = row.id
      if (this.classifyType == 3) {
        await this.getClassifySelectList(2, row.oneId)
      }
      const formKey = Object.getOwnPropertyNames(this.ruleForm)
      for (var key in row) {
        formKey.includes(key) ? this.$set(this.ruleForm, key, row[key]) : ''
      }
    }
  },
  methods: {

    /**
     * @description: 上传之前的文件处理
     * @param {待上传文件信息} file
     * @return {*}
     */
    beforeUpload (file) {
      const isLt2M = file.size < this.spaceData.max// file單位為byte
      const type = this.spaceData.mime.includes(file.type)
      if (!isLt2M) {
        this.$message.warning(
          `${file.name}文件过大，不能超出${this.spaceData.max / 1024 / 1024
          }M！`
        )
        const index = this.uploadFileList.findIndex(
          (item) => item.name == file.name
        )
        this.uploadFileList.splice(index, 1)
        return isLt2M
      }
      if (!type) {
        this.$message.warning('請上傳jpg/png文件！')
        const index = this.uploadFileList.findIndex(
          (item) => item.name == file.name
        )
        this.uploadFileList.splice(index, 1)
        return type
      }
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          img.onload = () => {
            if (img.naturalWidth / img.naturalHeight !== 1) {
              this.$message.error('请上传宽高比为1:1的图片!')
              return reject(false)
            } else {
              return resolve(true)
            }
          }
        }
      })
    },
    // 上传七牛云
    async customerUpload (request) {
      const { file } = request
      // let key = this.spaceData.unique_key + file.uid + file.name
      const params = new FormData()
      params.append('file', file)
      // params.append('token', this.spaceData.token)
      // params.append('key', key)

      await fileUpload(params).then(res => {
        if (res.status === 200) {
          this.filePath = '@imgUrl' + res.data.data.fileUrl
          if ($ENV == 'DEV') {
            this.imgBaseUrl = res.data.data.externalImgUrl
          } else {
            this.imgBaseUrl = res.data.data.insideImgUrl
          }
          console.log('this.imgBaseUrl', this.imgBaseUrl)
          this.$set(this.ruleForm, 'iconUrl', this.filePath)
        } else {
          this.$message.error(res.statusText)
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },

    upload (token, key, obj, next, error, complete) {
      const { file } = obj
      // 因人而异，自行解决
      let region = ''
      switch (this.spaceData.region) {
        case '10':
          region = qiniu.region.z0
          break
        case '20':
          region = qiniu.region.z1
          break
        case '30':
          region = qiniu.region.z2
          break
        case '40':
          region = qiniu.region.na0
          break
        case '50':
          region = qiniu.region.as0
          break
      }
      const putExtra = {
        // fname: file.name, // fname: string，文件原文件名
        params: {}, // params: object，用来放置自定义变量
        mimeType: null // mimeType: null || array，用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
      }
      const config = {
        useCdnDomain: true,
        region // z0华东区域 z1华北区域  z2代表华南区域  na0北美区域   as0新加坡区域
      }

      // let options = {
      //   // quality: 0.92,
      //   // noCompressIfLarger: true,
      //   // maxWidth: 1000,
      //   // maxHeight: 618,
      // }

      const observable = qiniu.upload(file, key, token, putExtra, config)
      const subscription = observable.subscribe(next, error, complete)
      return subscription
    },
    // 附件改变（增加删除时）
    fileChange (file, fileList) {
      const index = this.uploadFileList.findIndex(
        (item) => item.name == file.name
      )

      if (index == -1) {
        this.uploadFileList = fileList
      }
    },
    // 上传错误处理
    handleError (err) {
      console.log('上傳錯誤')
      this.$message.error(err)
    },
    /**
     * @description: 上传中的处理 让文件列表数据于显示的文件列表数据相同
     * @param {*} event
     * @param {*} file
     * @param {文件列表} fileList
     * @return {*}
     */
    fileProgress (event, file, fileList) {
      this.uploadFileList = fileList
    },
    handleChange (file, fileList) {
      const newList = fileList
      if (newList.length > 1) newList.shift()
      this.fileList = newList
    },
    handleSuccess (res, file) {
      console.log('handlesuccess', file)
      /* const file_name = file.name
      const file_ext = file.name.substring(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )
      const file_size = file.raw.size
      const file_path = this.spaceData.domain + `/${res.key}` //七牛云上绑定的域名 拼接了这个key 就是你上传文件的地址了
      上传成功后重组数据向父组件传对应格式的数据 与props里的curFileList数据格式对应
      const data = {
        file_ext,
        file_name,
        file_path,
        file_size,
      }
      this.attachment.push(data)
      this.$emit('syncFile', this.attachment) */
    },
    async getSpaceMethod () {
      this.uploadFileList = []
      this.attachment = []
      this.uploadDia = true
      const url = 'https://mtd.dakuu.cn/api/qiniu/qiniu_uptoken' // 获取七牛token的接口地址
      axios({
        method: 'get',
        url,
        params: {
          merchantid: 508
        }
      })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data
            this.$set(this.spaceData, 'token', data.uptoken)
            this.$set(this.spaceData, 'unique_key', data.key)
          } else {
            this.$message.error(res.statusText)
          }
        })
        .catch(() => { })
    },
    submitForm () {
      this.$refs.ruleForm.validate(async valid => {
        if ([2, 3].includes(this.classifyType)) {
          const oneNameVal = this.oneOptions.find(v => {
            return v.id == this.ruleForm.oneId
          })
          this.$set(this.ruleForm, 'oneName', oneNameVal.name)
          const twoNameVal = this.ruleForm.twoId ? this.twoOptions.find(v => {
            return v.id == this.ruleForm.twoId
          }) : ''
          this.$set(this.ruleForm, 'twoName', twoNameVal?.name || '')
        }
        if ([3].includes(this.classifyType)) {
          if (this.twoOptions.length != 0 && !this.ruleForm.twoId) {
            this.$message.error('请先选择二级分类之后再保存！')
            return
          }
        }
        if ([2].includes(this.classifyType)) {
          this.$message('需添加三级菜单，否则菜单将无法显示！')
        }
        if (valid) {
          if (this.classifyId) {
            console.log('edit', this.ruleForm)
            this.$set(this.ruleForm, 'id', this.classifyId)
            classifyEditInfo(this.ruleForm).then(res => {
              if (res.status == 200) {
                this.$message.success('编辑分類成功')
                console.log(res.data.info)
                this.formInit()
                this.$router.push('/categories')
                window.sessionStorage.setItem('activePath', '/categories')
              }
            }).catch(err => {
              console.log('catch_err', err)
            })
          } else {
            classifyAddInfo(this.ruleForm).then(res => {
              if (res.status == 200) {
                this.$message.success('添加分類成功')
                console.log(res.data.info)
                this.formInit()
                this.$router.push('/categories')
                window.sessionStorage.setItem('activePath', '/categories')
              }
            }).catch(err => {
              console.log('catch_err', err)
            })
          }
        } else {
          return this.$message.error('请填写必要的表单项')
        }
      })
    },
    formInit () {
      this.$refs.ruleForm.resetFields()
      this.$set(this, 'ruleForm', this.$options.data().ruleForm)
      this.$set(this, 'classifyId', this.$options.data().classifyId)
      this.$set(this, 'classifyType', this.$options.data().classifyType)
    },
    resetForm () {
      this.formInit()
      this.$router.push('/categories')
    },
    changeClassifyType (e) {
      this.classifyType = e
      this.$refs.ruleForm.clearValidate('iconUrl')
    },
    getClassifySelectList (type, parentId) {
      return new Promise((resolve, reject) => {
        const params = {
          type,
          parentId: this.ruleForm.oneId ? this.ruleForm.oneId : parentId
        }
        this.selectParentId = this.ruleForm.oneId ? this.ruleForm.oneId : parentId
        this.$set(this.ruleForm, 'twoId', null)
        classifySelectList(params).then(res => {
          if (res.status == 200) {
            const { data } = res.data
            if (type == 1) {
              this.oneOptions = data.data
            } else if (type == 2 && data.currentType == 2) {
              this.twoOptions = data.data
            } else {
              this.selectParentId = null
            }
            resolve()
          }
        }).catch(err => {
          reject()
          console.log('catch_err', err)
        })
      })
    },
    delClassify () {
      const params = {
        type: this.classifyType,
        id: this.classifyId
      }
      classifyEditDel(params).then(res => {
        if (res.status == 200) {
          this.$message.success('刪除成功')
          this.$router.push('/categories')
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    }
  }
}

</script>

<style lang="less">
.centerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .centerForm {
    // width: 70%;
    // text-align: center;
  }
}
</style>
