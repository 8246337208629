import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    count: 0,
    routerPath:'/reports',
    routerName:'數據統計'
  },
  mutations: {
    changePath (state,routerInfo) {
      console.log('index',routerInfo)
      let {activePath,activeName}=routerInfo
      state.routerPath=activePath
      state.routerName=activeName
    }
  }
})
export default store