<template>
  <div class="goodsAdd">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">工單管理</a></el-breadcrumb-item>
      <!-- <el-breadcrumb-item>添加商品</el-breadcrumb-item> -->
    </el-breadcrumb>

    <!-- <el-alert
			title="消息提示的文案"
			type="info"
			center
			:closable="false"
			show-icon>
		</el-alert> -->

    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" label-position="top"
      class="demo-ruleForm">
      <el-tabs tab-position="top" style="margin-top: 20px" v-model="activeIndex" @tab-click="tabClick"
        :before-leave="beforeLeave">
        <el-tab-pane label="工单详情" name='0'>
          <el-card class="formElCard">
            <el-row class="cardTitle">
              工單詳情
            </el-row>
            <div class="cardContent">
              <el-row class="cardContentRow">
                <el-col :span="6">
                  用戶ID：{{ addForm.userId }}
                </el-col>
                <el-col :span="6">
                  一級分類：{{ addForm.typeOneName }}
                </el-col>
                <el-col :span="6">
                  二級分類：{{ addForm.typeTwoName ? addForm.typeTwoName : '——' }}
                </el-col>
                <el-col :span="6">
                  三級分類：{{ addForm.typeThreeName }}
                </el-col>
              </el-row>
              <el-row class="cardContentRow">
                <el-col :span="6">
                  意見/投訴/表揚：{{ checkOpinionType }}
                </el-col>
                <el-col :span="6">
                  反饋日期：{{ addForm.opinionTime | dateFormat }}
                </el-col>
              </el-row>
            </div>
          </el-card>
          <el-card class="formElCard">
            <el-row class="cardTitle">
              工單進度
            </el-row>
            <div class="cardContent">
              <div class="centerbox">
                <el-row class="progressBarBox">
                  <el-col :span="8" class="progressBarItem" v-for="(progressItem, progressIndex) in progressList"
                    :key="progressIndex">
                    <el-col :span="8" class="progressIcon">
                      <i v-if="addForm.opinionStatus > progressItem.value || addForm.opinionStatus == 3"
                        class="el-icon-circle-check complete"></i>
                      <div v-else-if="addForm.opinionStatus == progressItem.value" class="progressIng"></div>
                      <div v-else class="incomplete"></div>
                    </el-col>
                    <el-col class="progressText" :span="7">{{ progressItem.text }}</el-col>
                    <el-col class="progressline" :span="8" v-show="progressIndex != 2"></el-col>
                  </el-col>
                </el-row>
                <div class="centerBtn">
                  <el-button v-if="!showSelect" type="danger" @click="changeStatus">修改</el-button>
                  <el-select v-else v-model="submitForm.status" placeholder="请选择" @change="changeStatusValue">
                    <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </el-card>
          <el-card class="formElCard">
            <el-row class="cardTitle">
              客戶留言
            </el-row>
            <div class="cardContent">
              <el-row>
                <el-col :span="10">
                  {{ addForm.opinionContents ? addForm.opinionContents : '空' }}
                </el-col>
              </el-row>
              <el-row v-if="suggestImgList.length != 0" class="cardContentImg">
                <el-image v-for="(sgItem, sgIndex) in suggestImgList" :key="sgIndex" class="customerImg" :src="sgItem"
                  fit="contain" @click="handlePreview(sgItem)">
                </el-image>
              </el-row>
            </div>
          </el-card>
          <el-card class="formElCard">
            <el-row class="cardTitle">
              補充資料
            </el-row>
            <div class="cardContent">
              <el-row class="cardContentRow">
                <el-col :span="6">
                  姓名：{{ complaintFormObj.name }}
                </el-col>
                <el-col :span="6">
                  聯繫電話：{{ complaintFormObj.phone }}
                </el-col>
                <el-col :span="6">
                  賬戶或卡號碼：{{ complaintFormObj.cardNumber }}
                </el-col>
                <el-col :span="6">
                  交易場所：{{ checkBank(complaintFormObj.tradingFloor) }}
                </el-col>
              </el-row>
              <el-row>
                <el-row>
                  <el-col :span="6">
                    是否需要回复：<span :style="{color:complaintFormObj.reply == 1?'#0BB204':'#000'}">{{ complaintFormObj.reply == 1 ? '是' : '否' }}</span>
                  </el-col>
                </el-row>
                <section v-if="complaintImgList.length != 0" class="cardContentImg">
                  <el-image v-for="(item, index) in complaintImgList" :key="index" class="customerImg" :src="item"
                    fit="contain" @click="handlePreview(item)">
                  </el-image>
                </section>
              </el-row>
            </div>
          </el-card>
          <el-card class="formElCard">
            <el-row class="cardTitle">
              工单备注
            </el-row>
            <div class="cardContent">
              <el-row>
                <el-form-item label="" prop="opinionRemark">
                  <el-input type="textarea" v-model="addForm.opinionRemark" placeholder="輸入備註"></el-input>
                </el-form-item>

              </el-row>
            </div>
          </el-card>
          <el-row style="text-align:center"><el-button type="danger" @click="submitFormInfo" >提交</el-button></el-row>

        </el-tab-pane>
        <el-tab-pane label="操作记录" name='1'>
          <el-card class="formElCard">
            <el-table :data="roleList" style="width: 100%" border>
              <el-table-column prop="sysUserName" label="賬戶" width="180">
              </el-table-column>
              <el-table-column prop="sysUserRole" label="身份" width="150">
              </el-table-column>
              <el-table-column prop="updateTime" label="操作日期" sortable>
                <template slot-scope="scope">
                  {{ scope.row.updateTime | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column prop="operation" label="操作">
              </el-table-column>
            </el-table>
            <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination> -->
          </el-card>
        </el-tab-pane>

      </el-tabs>
    </el-form>

    <el-dialog title="图片预览" :visible.sync="previewVisible" width="50%">
      <img :src="previewPath" class="previewImg"></img>
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
import { suggestEditInfo, suggestEdit, suggestRecordList } from '@/config/index'
export default {
  data() {
    return {
      activeIndex: 0,
      classifyId: null,
      addForm: {
        id: 1,
        userId: 1,
        typeOneId: 1,
        typeOneName: '',
        typeTwoId: 1,
        typeTwoName: '',
        typeThreeId: 1,
        typeThreeName: '',
        opinionType: 1,
        opinionTime: '',
        opinionStatus: 1,
        opinionContents: null,
        opinionImg: null,
        opinionSupplement: '',
        opinionRemark: '',
        opinionDesc: null

      },
      cateList: [],
      cateListSty: {
        label: 'cat_name',
        value: 'cat_id',
        children: 'children',
        expandTrigger: 'hover'

      },
      addFormRules: {
        // goods_name: [
        //   { required: true, message: '请输入商品名称', trigger: 'blur' },
        //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        // ],
        // goods_price: [
        //   { required: true, message: '请输入商品价格', trigger: 'change' }
        // ],
        // goods_number: [
        //   { required: true, message: '请输入商品数量', trigger: 'blur' }
        // ],
        // goods_weight: [
        //   { required: true, message: '请输入商品重量', trigger: 'blur' }
        // ],
        // goods_cat: [
        //   { required: true, message: '请选择商品分类', trigger: 'blur' }
        // ]


      },
      manyTableData: [],
      onlyTableData: [],
      fileList: [],
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      previewPath: '',
      previewVisible: false,
      editorContent: '',
      editorOption: {
      },
      progressList: [
        {
          value: 1,
          text: '交由負責部門跟進'
        },
        {
          value: 2,
          text: '已與客戶聯絡'
        },
        {
          value: 3,
          text: '處理完成'
        }
      ],
      opinionType: 2,
      typeOptions: [
        {
          value: 1,
          label: '未處理'
        }, {
          value: 2,
          label: '處理中'
        }, {
          value: 3,
          label: '已處理'
        }
      ],
      value: '',
      showSelect: false,
      roleList: [
        {
          "id": 1,
          "sysUserId": 1,
          "sysUserName": "admin",
          "sysUserRole": "管理員",
          "operation": "用戶信息查詢",
          "createTime": 1672107497000,
          "updateTime": 1672107497000
        },
        {
          "id": 2,
          "sysUserId": 1,
          "sysUserName": "admin",
          "sysUserRole": "管理員",
          "operation": "用戶-信息查詢",
          "createTime": 1672212963000,
          "updateTime": 1672212963000
        },
        {
          "id": 3,
          "sysUserId": 1,
          "sysUserName": "admin",
          "sysUserRole": "管理員",
          "operation": "用戶-信息查詢",
          "createTime": 1672213137000,
          "updateTime": 1672213137000
        },
        {
          "id": 4,
          "sysUserId": 1,
          "sysUserName": "admin",
          "sysUserRole": "管理員",
          "operation": "工單管理-分頁查詢",
          "createTime": 1672213232000,
          "updateTime": 1672213232000
        },
      ],
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 10
      },
      suggestImgList: [],
      complaintImgList: [],
      complaintFormObj: {
        name: '',
        phone: '',
        cardNumber: '',
        reply: 1,
        imgUrl: ''
      },
      submitForm: {
        id: '',
        status: '',
        opinionRemark: ''
      },
      bankColumns: [{
						label: '置地總行',
						id: 1
					}, {
						label: '宋玉生廣場分行',
						id: 2
					}, {
						label: '工銀大廈分行',
						id: 3
					}, {
						label: '葡京分行',
						id: 4
					},
					{
						label: '新馬路議事亭分行',
						id: 5
					}, {
						label: '南光大廈分行',
						id: 6
					}, {
						label: '高士德分行',
						id: 7
					},
					{
						label: '信步閑庭分行',
						id: 8
					},
					{
						label: '黑沙環分行',
						id: 9
					},
					{
						label: '台山分行',
						id: 10
					}, {
						label: '海灣分行',
						id: 11
					}, {
						label: '濠景分行',
						id: 12
					}, {
						label: '威尼斯人分行',
						id: 13
					}, {
						label: '銀河分行',
						id: 14
					}, {
						label: '新濠天地分行',
						id: 15
					},
					{
						label: '新濠影匯分行',
						id: 16
					},
					{
						label: '上葡京分行',
						id: 17
					},
					{
						label: '中華廣場服務中心',
						id: 18
					},
					{
						label: '下環街服務中心',
						id: 19
					},
					{
						label: '俾利喇服務中心',
						id: 20
					}
				],

    }
  },
  computed: {
    cateId() {
      if (this.addForm.goods_cat.length === 3) {
        return this.addForm.goods_cat[2]
      }
      return null
    },
    editor() {
      return this.$refs.myQuillEditor.quill
    },
    checkOpinionType(){
      
        switch(this.addForm.opinionType){
          case 1:
            return '意見反饋'
          case 2:
            return '投訴建議'
          case 4:
            return '表揚'
          default:
          return '表揚'
        }
      

    }
  },
  created() {
    //this.getCategoryList();
  },
  mounted() {
    let { row } = this.$route.query
    console.log('130', row)
    if (row?.id) {
      this.classifyId = row.id
      this.getCategoryList()
      this.getRoleList()
      // let formKey = Object.getOwnPropertyNames(this.editUserForm)
      // for (var key in row) {
      //   formKey.includes(key) ? this.$set(this.editUserForm, key, row[key]) : ''
      // }
      // console.log('107_form', this.editUserForm)
    }
  },
  methods: {
    async getCategoryList() {
      await suggestEditInfo({ id: this.classifyId }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          let formKey = Object.getOwnPropertyNames(this.addForm)
          for (var key in data) {
            formKey.includes(key) ? this.$set(this.addForm, key, data[key]) : ''
          }
          if (this.addForm.opinionImg) {
            this.suggestImgList = this.addForm.opinionImg.split(",")
          }
          this.complaintFormObj = JSON.parse(this.addForm.opinionSupplement)
          console.log('complaintFormObj', this.complaintFormObj)
          if (this.complaintFormObj.imgUrl) {
            this.complaintImgList = this.complaintFormObj.imgUrl.split(",")
          }
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    async submitFormInfo() {
      this.$set(this.submitForm, 'id', this.classifyId)
      this.$set(this.submitForm, 'opinionRemark', this.addForm.opinionRemark)
      this.$set(this.submitForm, 'status', this.addForm.opinionStatus)
      await suggestEdit(this.submitForm).then(res => {
        if (res.status == 200) {
          this.$message.success('提交成功')
          this.getCategoryList()
          this.$router.push('/goods')
          window.sessionStorage.setItem("activePath",'/goods');
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    handleChange() {
      if (this.addForm.goods_cat.length !== 3) {
        this.addForm.goods_cat = []
      }
      console.log(this.addForm.goods_cat)

    },
    beforeLeave(activeName, oldActiveName) {
      // if(oldActiveName==='0'&&this.addForm.goods_cat.length!==3){
      // 	this.$message.error('请先选择商品分类')
      // 	return false
      // }

    },
    async tabClick() {
      // if(this.activeIndex==1){

      // 	const {data:res}=await this.$http.get(`categories/${this.cateId}/attributes`,{params:{sel:'many'}
      // 		})

      // 	if(res.meta.status!==200){
      // 		return this.$message.error("获取商品列表失败")
      // 	}

      // 	res.data.forEach(item=>{
      // 		item.attr_vals=item.attr_vals.length===0?[]:item.attr_vals.split(' ')
      // 	})
      // 	this.manyTableData=res.data

      // }else if(this.activeIndex==2){
      // 	console.log('加载')
      // 	const {data:res}=await this.$http.get(`categories/${this.cateId}/attributes`,{params:{sel:'only'}
      // 		})

      // 	if(res.meta.status!==200){
      // 		return this.$message.error("获取商品属性失败")
      // 	}
      // 	console.log(res)

      // 	this.onlyTableData=res.data
      // }

    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      const filePath = file.response.data.tmp_path;
      const fileIndex = this.addForm.pics.findIndex(item => {
        item.pic === filePath
      })
      this.addForm.pics.splice(fileIndex, 1)
      console.log(this.addForm)
    },
    handlePreview(fileUrl) {
      this.previewPath = fileUrl
      this.previewVisible = true
    },
    goodsPicsAdd(res) {
      const picInfo = { pic: res.data.tmp_path }
      this.addForm.pics.push(picInfo)
      console.log(this.addForm)
    },
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    addGoodsInfo() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const cloneForm = _.cloneDeep(this.addForm)
          cloneForm.goods_cat = cloneForm.goods_cat.join(',')

          //动态参数
          this.manyTableData.forEach(item => {
            const newInfo = {
              attr_id: item.attr_id,
              attr_value: item.attr_vals.join(' ')
            }
            this.addForm.attrs.push(newInfo)
          })
          //静态参数
          this.onlyTableData.forEach(item => {
            const newInfo = {
              attr_id: item.attr_id,
              attr_value: item.attr_vals
            }
            this.addForm.attrs.push(newInfo)
          })
          cloneForm.attrs = this.addForm.attrs;
          console.log('cloneForm', cloneForm)
          const { data: res } = await this.$http.post('goods', cloneForm)


          if (res.meta.status !== 201) {
            return this.$message.error("添加商品失败")
          }
          this.$message.success("添加商品成功")
          this.$router.push('/goods')

        } else {
          return this.$message.error('请填写必要的表单项')
        }
      })
    },
    changeStatus() {
      this.showSelect = !this.showSelect
    },
    changeStatusValue(val) {
      this.showSelect = !this.showSelect
      this.$set(this.addForm,'opinionStatus',val)
    },
    async getRoleList() {
      await suggestRecordList({ id: this.classifyId }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          this.roleList = data
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getRoleList();

    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getRoleList();

    },
    checkBank(id){
      const item=this.bankColumns.find(v=>{
        return v.id==id                 
      })
      return item?.label
    }
  },

}

</script>
<style lang="less" scoped>
.goodsAdd .el-checkbox {
  margin: 0 10px 0 0px !important;
}

.previewImg {
  width: 100%
}

.qlBtn {
  margin-top: 15px
}

.formElCard {
  margin-bottom: 18px;
}

.cardTitle {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  margin-bottom: 32px;
}

.cardContent {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;

  .cardContentImg {
    margin-top: 24px;
  }

  .cardContentRow {
    margin-bottom: 15px;
  }

  .centerbox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .centerBtn {
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
    }

  }

  .progressBarBox {
    width: 80%;
    //left:15%;
    display: flex;
    justify-content: center;
    align-items: center;

    .progressBarItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 5px;

      .progressIcon {
        width: 40px;
        height: 40px;
        color: #EB4343;

        .progressIng {
          width: 32px;
          height: 32px;
          background: #fff;
          border-radius: 50%;
          border: 1px solid #EB4343;
          position: relative;

          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            background: #FF7979;
            border-radius: 50%;
          }
        }

        .incomplete {
          width: 32px;
          height: 32px;
          background: #fff;
          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.47);
        }

        .complete {
          font-size: 40px;
        }
      }

      .progressText {
        flex-shrink: 0;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 24px;
        margin: 0 5px 0 8px;
        text-align: left;
      }

      .progressline {
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.customerImg {
  width: 88px;
  height: 88px;
  margin-right: 26px;
  cursor: pointer;
}
</style>