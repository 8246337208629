<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">賬號管理</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">{{
          userId ? "編輯賬號" : "新增賬號"
        }}</a></el-breadcrumb-item>
      <!-- <el-breadcrumb-item>商品分类</el-breadcrumb-item> -->
    </el-breadcrumb>
    <el-card>
      <div class="centerBox">
        <el-row class="centerForm">
          <el-form :model="editUserForm"
                   :rules="addUserRules"
                   ref="editFormRef"
                   label-width="80px"
                   class="demo-ruleForm">
            <el-form-item label="賬號"
                          prop="username">
              <el-input v-model="editUserForm.username"
                        placeholder="由字母/數字組成，不超過25個字符"></el-input>
            </el-form-item>
            <section v-if="type!=='mine'">
              <el-form-item :label="'密碼'"
                            prop="password">
                <el-input v-model="editUserForm.password"
                          type="password"
                          placeholder="8～16個字符，必須包含大小寫字母,數字和特殊字符"></el-input>
              </el-form-item>
            </section>
            <el-form-item label="姓名"
                          prop="name">
              <el-input v-model="editUserForm.name"
                        placeholder="不超過25個字符"></el-input>
            </el-form-item>
            <el-form-item label="邮箱"
                          prop="email">
              <el-input v-model="editUserForm.email"
                        placeholder="請輸入"></el-input>
            </el-form-item>
          </el-form>
        </el-row>
        <div>
          <el-button v-if="userId"
                     @click="deleteUser()">刪除賬戶</el-button>
          <el-button type="danger"
                     @click="submitForm('editUserForm')">{{
            userId ? "確認修改" : "新增"
          }}</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { userEdit, userAdd, userDel } from '@/config/index'
import secondList from '../classification/secondList.vue'
export default {
  components: { secondList },
  data () {
    return {
      userId: null,
      classifyType: 1,
      editUserForm: {
        id: '',
        name: '',
        username: '',
        password: '',
        email: ''
      },
      addUserRules: {
        username: [
          { required: true, message: '請輸入賬號', trigger: 'blur' },
          { min: 3, max: 25, message: '长度在 3 到 25 个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '請輸入姓名', trigger: 'blur' },
          { min: 3, max: 25, message: '长度在 3 到 25 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '請輸入密碼', trigger: 'blur' },
          { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' }
        ],
        email: [{ required: true, message: '請輸入郵箱', trigger: 'blur' }]
      },
      fileList: [],
      oneOptions: [
        {
          value: 1,
          label: '一級分類1'
        },
        {
          value: 2,
          label: '一級分類2'
        },
        {
          value: 3,
          label: '一級分類3'
        }
      ],
      twoOptions: [
        {
          value: 1,
          label: '二級分類1'
        },
        {
          value: 2,
          label: '二級分類2'
        },
        {
          value: 3,
          label: '二級分類3'
        }
      ],
      spaceData: {
        mime: [], // 后台限制的文件格式
        space: '', // 存储空间标识(七牛云or阿里云)
        domain: 'https://img-cdn.dakuu.cn', // 存储域名
        token: '',
        unique_key: '',
        region: '30', // 存储区域
        max: 5242880
      },
      uploadFileList: [], // 上传文件列表  {name,size,percentage,status,uid}  uid唯一标识
      attachment: [], // 已上传文件列表 与父组件的数据对应
      filePath: '',
      type: '',
      role: 2
    }
  },
  computed: {
    qiniuDomain () {
      return function (region) {
        switch (region) {
          case '10':
            return 'https://upload.qiniup.com'
          case '20':
            return 'https://upload-z1.qiniup.com'
          case '30':
            return 'https://upload-z2.qiniup.com'
          case '40':
            return 'https://upload-na0.qiniup.com'
          case '50':
            return 'https://upload-as0.qiniup.com'
          default:
            return 'https://upload-z1.qiniup.com'
        }
      }
    }
  },
  created () { },
  mounted () {
    const { row, type } = this.$route.query
    const { role } = JSON.parse(window.sessionStorage.getItem('userInfo'))
    this.role = Number(role)
    if (row?.id) {
      this.userId = row.id
      this.type = type
      const formKey = Object.getOwnPropertyNames(this.editUserForm)
      for (var key in row) {
        formKey.includes(key)
          ? this.$set(this.editUserForm, key, row[key])
          : ''
      }
    }
  },
  methods: {
    submitForm () {
      if (this.role !== 1) {
        this.$message.error('暫無權限')
        return
      }
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          let newEditUserForm = {}
          if (this.type == 'mine') {
            newEditUserForm = {
              name: this.editUserForm.name,
              username: this.editUserForm.username,
              email: this.editUserForm.email
            }
          } else {
            newEditUserForm = {
              name: this.editUserForm.name,
              username: this.editUserForm.username,
              password: this.$Base64.encode(this.editUserForm.password),
              email: this.editUserForm.email
            }
          }

          if (this.userId) {
            this.$set(newEditUserForm, 'id', this.userId)
            userEdit(newEditUserForm)
              .then(res => {
                if (res.status == 200) {
                  if (res.data.code == '0000') {
                    this.formInit()
                    if (this.type == 'mine') {
                      this.$message.success('修改成功，请重新登录！')
                      window.sessionStorage.clear()
                      this.$router.push('/login')
                    } else {
                      this.$message.success('修改成功')
                      this.$router.push('/users')
                    }
                  } else {
                    this.$message.error(res.data.info)
                  }
                }
              })
              .catch(err => {
                console.log('catch_err', err)
              })
          } else {
            userAdd(newEditUserForm)
              .then(res => {
                if (res.status == 200) {
                  if (res.data.code == '0000') {
                    this.$message.success('添加成功')
                    this.formInit()
                    this.$router.push('/users')
                  } else {
                    this.$message.error(res.data.info)
                  }
                }
              })
              .catch(err => {
                console.log('catch_err', err)
              })
          }
        } else {
          return this.$message.error('请填写必要的表单项')
        }
      })
    },
    deleteUser () {
      userDel({ id: this.userId })
        .then(res => {
          console.log('res', res)
          if (res.status == 200) {
            this.$message.success('刪除成功')
            this.formInit()
            this.$router.push('/users')
          }
        })
        .catch(err => {
          console.log('catch_err', err)
        })
    },
    formInit () {
      this.$refs.editFormRef.resetFields()
      this.$set(this, 'editUserForm', this.$options.data().editUserForm)
      this.$set(this, 'userId', this.$options.data().userId)
    },
    resetForm () {
      this.formInit()
      this.$router.push('/users')
    }
  }
}
</script>

<style lang="less" scoped>
.centerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .centerForm {
    width: 70%;
  }
}
</style>
