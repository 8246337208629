import http from './axios'

const loginText = (data) => http.post('/sys/user/login', data)// 登錄接口
const suggestList = (data) => http.post('/sys/wo/page', data)// 工單列表
const suggestEditInfo = (params) => http.get('/sys/wo/get', params)// 工單信息查詢
const suggestEdit = (data) => http.post('/sys/wo/update', data)// 工單信息提交
const suggestRecordList = (params) => http.get('/sys/wo/operation/log', params)// 工單信息操作記錄
const classifySelectList = (params) => http.get('/sys/type/get', params)// 分類篩選
const classifyAddInfo = (data) => http.post('/sys/type/add', data)// 分類新增
const classifyEditInfo = (data) => http.post('/sys/type/edit', data)// 分類修改
const classifyEditDel = (params) => http.get('/sys/type/dle', params)// 分類刪除
const operateClassify = (params) => http.get('/sys/type/operate/status', params)// 分類上下架
const classifyList = (data, params) => http.post('/sys/type/page', data, params)// 分類列表
const userList = (data) => http.post('/sys/user/page', data)// 賬戶列表
const userEdit = (data) => http.post('/sys/user/edit', data)// 賬戶編輯
const userAdd = (data) => http.post('/sys/user/add', data)// 賬戶編輯
const userDel = (params) => http.get('/sys/user/dle', params)// 賬戶刪除
const noticList = (data) => http.post('/sys/en/page', data)// 通知列表
const noticAdd = (data) => http.post('/sys/en/add', data)// 通知列表
const noticEdit = (data) => http.post('/sys/en/edit', data)// 通知編輯
const noticDel = (params) => http.get('/sys/en/dle', params)// 賬戶刪除
const noticBatchDel = (data) => http.post('/sys/en/batch/dle', data)// 賬戶批量刪除
const fileUpload = (data) => http.post('/file/upload', data)// 文件上传
const reportList = (params) => http.get('/data/find', params)// 分類統計
const viewList = (params) => http.get('/data/stat/find', params)// 折线图統計
const logoutText = (params) => http.get('/sys/user/logout', params)// 登出接口
const editPsd = (data) => http.post('/sys/user/pwd/reset', data)// 個人編輯密碼接口
const editMyself = (data) => http.post('/sys/user/myself/edit', data)// 個人編輯信息接口

export {
    loginText,
    suggestList,
    suggestEditInfo,
    suggestEdit,
    suggestRecordList,
    classifyAddInfo,
    classifyEditInfo,
    operateClassify,
    userList,
    userEdit,
    userAdd,
    userDel,
    noticList,
    noticAdd,
    classifyList,
    classifySelectList,
    noticDel,
    classifyEditDel,
    noticBatchDel,
    fileUpload,
    noticEdit,
    reportList,
    viewList,
    logoutText,
    editPsd,
    editMyself
}
