<template>
  <div class="login_container">
    <el-row>
      <el-col :lg="14" :md="11" :sm="24" :xl="14" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
      <el-col :lg="9" :md="12" :sm="24" :xl="9" :xs="24">
        <!-- <div class="hudong_logo"></div> -->
        <div class="hudong_title">达酷互动H5</div>
        <div class="login_box">
          <!--头像区域-->
          <!-- <div class="avater_box">
		<img src="../assets/logo.png">
	</div> -->

          <div class="logo_text">
            登录达酷互动H5
          </div>
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="rules"
            label-width="0"
            class="login_form"
            :show-message="true"
            :status-icon="true"
          >
            <el-form-item prop="username">
              <el-input
                v-model="loginForm.username"
                class="input-new"
                :style="{ color: isErr ? '#FF5353' : '#333333' }"
                :placeholder="isErr ? '账号不能为空' : '账户'"
              >
                <i slot="prefix" class="el-input__icon login_name"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="loginForm.password"
                type="password"
                class="input-new"
                :style="{ color: isErr ? '#FF5353' : '#333333' }"
                :placeholder="isErr ? '密码不能为空' : '密码'"
              >
                <i slot="prefix" class="el-input__icon login_psd"></i>
              </el-input>
            </el-form-item>
            <el-form-item class="btns">
              <div class="btn_login" @click="login">登录</div>

              <!-- <el-button type="primary" @click="login">登陆</el-button>
          <el-button type="info" @click="reset">重置</el-button> -->
            </el-form-item>
            <div class="btns">
              <!-- <a class="btn_a" href="https://jinshuju.net/f/tZt5Er">申请使用</a> -->
            </div>
          </el-form>
        </div>
      </el-col>
      <el-col :lg="1" :md="1" :sm="24" :xl="1" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
    </el-row>
    <el-row class="copyright">
      <div class="copyright-text">
        Copyright 2020 - 2023 DigitalBay. All Rights Reserved. 达酷互动科技
        版权所有
      </div>
      <div class="copyright-text" @click="openWeb">粤ICP备 2020142040号-5</div>
    </el-row>
  </div>
</template>
<script>
import { loginText, suggestList } from "@/config/index";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 2, max: 10, message: "长度在 3 到 10 个字符", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 8, max: 16, message: "长度在 8 到 16 个字符", trigger: "blur" }
        ]
      },
      isErr: false
    };
  },
  methods: {
    reset() {
      this.$refs.loginForm.resetFields();
    },
    testurl() {
      const searchForm = {
        page: 1,
        pageSize: 10
      };
      suggestList(searchForm)
        .then(res => {
          console.log("res", res);
          if (res.status == 200) {
            console.log(res.data);
          }
        })
        .catch(err => {
          console.log("catch_err", err);
        });
    },
    login() {
      this.$message.error("仅限商户登入");
      return;
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          const newLoginForm = {
            password: btoa(this.loginForm.password),
            username: this.loginForm.username
          };

          loginText(newLoginForm)
            .then(res => {
              console.log("res", res);
              if (res.status == 200) {
                if (res.data.code == "0000") {
                  const token = res.data.data.accessTokenEntity.accessToken;
                  const expTime = res.data.data.accessTokenEntity.expireTime;
                  const {
                    id,
                    name,
                    username,
                    email,
                    remark,
                    role
                  } = res.data.data;

                  const userInfo = {
                    id,
                    name,
                    username,
                    email,
                    remark,
                    role,
                    password: btoa(this.loginForm.password)
                  };
                  window.sessionStorage.setItem("token", token);
                  window.sessionStorage.setItem("expTime", expTime);
                  // window.sessionStorage.setItem("name", name);
                  window.sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(userInfo)
                  );
                  this.$message("登录成功");
                  this.$router.push("/home");
                } else {
                  this.$message.error(res.data.info);
                }
              }
            })
            .catch(err => {
              console.log("catch_err", err);
            });
        } else {
        }
      });
    },
    openWeb() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    }
  }
};
</script>
<style lang="less">
.el-form-item {
  //padding: 5px 0;
  padding-right: 0;
  margin: 30px 0;
  color: #454545;
  background: transparent;
  //border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  .error-text {
    position: absolute;
    top: 47px;
    left: 19px;
    margin: 0 auto;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-size: 12px;
    line-height: 23px;
    color: #ff4d4f;
    .error-psd {
      margin-left: 80px;
    }
    .error-icon {
      position: absolute;
      top: 5px;
      left: -16px;
      display: inline-block;
      width: 12px;
      height: 12px;
      vertical-align: middle;
      background: url("https://img-cdn.dakuu.cn/o_1h049btqd1k3ijvq1am41bss1h61c.png")
        center no-repeat;
      background-size: 100% 100%;
    }
  }
  .form-valid-success {
    position: absolute;
    top: 18px;
    right: -25px;
    display: inline-block;
    width: 12px;
    height: 12px;
    vertical-align: middle;
    background: url("https://img-cdn.dakuu.cn/o_1h049btqdia7oos12r4avq13hgb.png")
      center no-repeat;
    background-size: 100% 100%;
  }

  &__content {
    min-height: 32px;
    line-height: 32px;
  }

  &__error {
    position: absolute;
    top: 100%;
    left: 18px;
    font-size: 12px;
    line-height: 18px;
    color: #ff4d4f;
  }
}
.el-input {
  box-sizing: border-box;

  input {
    height: 76px;
    padding-left: 78px;
    font-size: 22px;
    line-height: 60px;
    background: #fff !important;
    //border: 0;
    border-radius: 11px;
    &:focus {
      outline: none !important;
    }
  }
}
.el-input__inner {
  &:focus,
  &:hover {
    border: 1px solid #dcdfe6 !important;
  }
}

.login_container {
  // width: 100%;
  position: relative;
  background-color: #ffc101;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #f1f4fd;
  background-image: url("https://img-cdn.dakuu.cn/o_1h5hj0tpn1a9g1qpg10ufd99893a.png");

  .hudong_logo {
    width: 270px;
    height: 149px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    background-image: url("https://img-cdn.dakuu.cn/o_1go8q878917o513i61djk10g8h73b.png");
  }
  .hudong_title {
    position: absolute;
    top: 50%;
    left: 15%;
    width: 480px;
    height: 160px;
    transform: translateY(-40px);
    font-size: 80px;
    font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
    font-weight: 800;
    color: #333333;
    line-height: 90px;
  }

  .login_box {
    position: relative;
    // right: 105px;
    // top: 97px;
    width: 592px;
    //height: 560px;
    box-sizing: border-box;
    // height: 280px;
    padding: 58px 57px;
    margin: calc((100vh - 500px) / 2) 1vw 5vw;
    //calc((100vh - 50vh - 80px) / 2)
    // margin-left: auto;
    // overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 0px 11px 0px rgba(230, 164, 133, 0.32);
    border-radius: 51px;
    text-align: center;

    .logo_text {
      // position: absolute;
      // top: 29px;
      // left: 50%;
      // transform: translateX(-50%);

      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
      font-size: 36px;
      line-height: 50px;
    }

    .login_form {
      // position: absolute;
      // top: 76px;
      // left: 0;
      width: 100%;
      margin-top: 4vh;
      // padding: 0 28px;
      box-sizing: border-box;

      .el-form-item {
        margin-bottom: 3vh;

        .el-input {
          .el-input__inner {
            padding-left: 78px !important;
          }

          .err_color {
            color: #ff5353;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      .login_name {
        display: inline-block;
        width: 30px;
        height: 34px;
        margin-left: 25px;
        transform: translateY(50%);
        background-image: url("../assets/img/login_user.png");
        background-position: 0% 0%;
        background-size: auto 100%;
        background-repeat: no-repeat;
      }

      .login_psd {
        display: inline-block;
        width: 26px;
        height: 36px;
        margin-left: 28px;
        transform: translateY(50%);
        background-image: url("../assets/img/login_psd.png");
        background-position: 0% 0%;
        background-size: auto 100%;
        background-repeat: no-repeat;
      }

      .btns {
        display: flex;
        justify-content: center;
        text-align: center;
        .el-form-item__content {
          flex: 1;
        }

        .btn_login {
          width: 100%;
          //height: 38px;
          background: #ffc101;
          border-radius: 8px;
          color: #fff;
          line-height: 55px;
          text-align: center;
          width: 100%;
          height: 55px;
          margin-top: 5px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          //line-height: 30px;
          border: none;
          border-radius: 14px;
          font-size: 26px;
          //box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
        }

        .btn_a {
          text-decoration: none;
          font-size: 26px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffc101;
          line-height: 32px;

          // a:visited {
          //   color: #ec4343;
          // }
        }
      }
    }

    .avater_box {
      height: 130px;
      width: 130px;
      border-radius: 50%;
      border: 1px solid #eee;
      padding: 10px;
      box-shadow: 0 0 10px #ddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
      }
    }
  }
  .copyright {
    position: fixed;
    bottom: 20px;
    width: 100%;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    text-align: center;
    .copyright-text {
      line-height: 26px;
      cursor: pointer;
    }
    .versionCode {
      // margin-top: 10px;
      font-size: 14px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      letter-spacing: 0.75px;
    }
  }
}
</style>
