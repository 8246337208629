<template>
  <el-card>
    <el-table :data="goodsList"
              style="width: 100%"
              border>
      <el-table-column prop="name"
                       label="名稱">
      </el-table-column>
      <el-table-column prop="orderNo"
                       label="排序"
                       width="220">
        <template slot-scope="scope">
          <!-- <div v-if="" class="sortData">{{scope.row.orderNo}}</div> -->
          <el-input-number v-model="scope.row.orderNo"
                           @change="(currentValue,oldValue)=>handleChange(scope.row,currentValue,oldValue)"
                           :min="0"
                           size="mini"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       width="180px">
        <template slot-scope="scope">
          <el-button type="text"
                     size="mini"
                     @click="showEditDialog(scope.row)"
                     class="editBtn">編輯</el-button>
          <el-button type="text"
                     size="mini"
                     @click="showClassify(1, scope.row.id)"
                     class="editDis"
                     v-if="scope.row.status==0">上架</el-button>
          <el-button type="text"
                     size="mini"
                     @click="showClassify(0,scope.row.id)"
                     class="editBtn"
                     v-else>下架</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="queryInfo.page"
                   :page-sizes="[10, 20, 40, 50]"
                   :page-size="queryInfo.pageSize"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total">
    </el-pagination>
  </el-card>
</template>
<script>
import { operateClassify, classifyList, classifyEditInfo } from '@/config/index'
export default {
  props: {
    gameid: {
      type: [Number, String],
      required: false,
      default: '',
    },
    classifyType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data () {
    return {
      queryInfo: {
        query: '',
        page: 1,
        pageSize: 10
      },
      searchForm: {
        parentId: '',
        type: 1
      },
      total: 0,
      goodsList: [],
      ruleForm: {
        id: '',
        name: '',
        type: 1,
        iconUrl: '',
        oneId: '',
        oneName: '',
        twoId: '',
        twoName: '',
        orderNo: 0
      },

    }
  },
  computed: {
    opinionStatusText () {
      return (val) => {
        switch (val) {
          case 1:
            return {
              color: '#52C41A',
              text: '已處理'
            }
          case 2:
            return {
              color: '#FFB333',
              text: '處理中'
            }
          default:
            return {
              color: '#FF827E',
              text: '未處理'
            }
        }
      }
    }
  },
  created () {
    this.getGoodsList();

  },
  methods: {
    async getGoodsList () {
      await classifyList(this.queryInfo, this.searchForm).then(res => {
        if (res.status == 200) {
          let { data } = res.data.data
          this.goodsList = data?.items;
          this.total = data.total;
        }
      }).catch(err => {
        console.log('catch_err', err)
      })

    },
    showEditDialog (row) {
      this.$router.push({ path: '/categories/edit', query: { classifyType: this.classifyType, row: row } })
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize;
      this.getGoodsList();

    },
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage;
      this.getGoodsList();

    },

    showClassify (status, id) {
      let params = {
        type: this.classifyType == 'first' ? 1 : this.classifyType == 'second' ? 2 : 3,
        id: id,
        status
      }
      operateClassify(params).then(res => {
        console.log('res', res)
        if (res.status == 200) {
          this.$message.success(`${status == 0 ? '下架' : '上架'}成功`)
          this.getGoodsList()
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    async handleChange (row, currentValue, oldValue) {
      let formKey = Object.getOwnPropertyNames(this.ruleForm)
      for (var key in row) {
        formKey.includes(key) ? this.$set(this.ruleForm, key, row[key]) : ''
      }
      console.log('index', this.ruleForm)
      let newRuleForm = {
        orderNo: currentValue,
        ...this.ruleForm,
      }
      await classifyEditInfo(newRuleForm).then(res => {
        console.log('res', res)
        if (res.status == 200) {
          this.$message.success("修改排序成功")
          this.getGoodsList()
        }
      }).catch(err => {
        console.log('catch_err', err)
      })

    }


  }
}
</script>
<style lang="less">
.opinionTypeCircle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ffb333;
  margin-right: 8px;
  border-radius: 50%;
}

.el-card__header {
  border-bottom: none !important;
}

.textRight {
  text-align: right;
}

.headerClassify {
  display: flex;
  justify-content: center;
  align-items: center;

  .classifyIcon {
    display: block;
    width: 19px;
    height: 17px;
  }
}

.sortData {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 30px;
}
</style>