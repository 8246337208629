<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">数据统计</a></el-breadcrumb-item>
      <!-- <el-breadcrumb-item>数据报表</el-breadcrumb-item> -->
    </el-breadcrumb>
    <el-row class="filterOption">
      <!-- <el-col :span="2"></el-col> -->

      <div class="filterTime">數據時間
        <el-date-picker v-model="value1" value-format="yyyy-MM-dd HH:mm:ss" type="daterange"
          :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          @change="setTimeRange">
        </el-date-picker>
      </div>
      <el-image src="https://img-cdn.dakuu.cn/o_1gnucpr5o1eqj6t71h3h1t5s1mg9a.png" style="width:30px;margin-left:5px"
        fit="contain">
      </el-image>

    </el-row>
    <el-row class="elContent">
      <div class="elCard">
        <el-card class="access width">
          <div class="card-title">
            <div class="card-text">類型統計</div>
            <div class="card-btn">

              <div :class="['card-btn-item', selectedType == 'one' ? 'card-btn-item-border' : '']"
                @click="changeType('one')">
                一級分類
              </div>
              <div :class="['card-btn-item', selectedType == 'two' ? 'card-btn-item-border' : '']"
                :style="{ borderLeft: selectedType == 'one' ? '1px solid #EB4343' : '' }" @click="changeType('two')">
                二級分類<span class="classifyIcon">
                </span></div>
              <div :class="['card-btn-item', selectedType == 'three' ? 'card-btn-item-border' : '']"
                :style="{ borderLeft: selectedType == 'two' ? '1px solid #EB4343' : '' }" @click="changeType('three')">
                三級分類<span class="classifyIcon">
                </span></div>

            </div>
          </div>
          <div id="userMain" class="card-content"></div>
        </el-card>
      </div>
    </el-row>
    <el-row class="elContent">
      <!-- <el-col :span="8">
        <div class="elCard">
          <el-card class="access width">
            <div class="card-title">
              <div class="card-text">分行統計</div>
              <div class="card-btn">


                <div :class="['card-btn-item card-btn-item-border']" @click="changeType(2)">
                  全部分行<span class="classifyIcon">
                  </span></div>

              </div>
            </div>
            <div id="bankAmount"></div>
          </el-card>
        </div>
      </el-col> -->
      <el-col :span="24">
        <div class="elCard">
          <el-card class="access width">
            <div class="card-title">
              <div class="card-text">平台瀏覽量</div>
              <div class="card-btn">
                <!-- <div :class="['card-btn-item',selectedType==1?'card-btn-item-border':'']" @click="changeType(2)">全部分行<span class="classifyIcon">
                    </span></div> -->
              </div>
            </div>
            <div class="sub-title">累計{{visitorCount}}</div>
            <div id="viewAmount"></div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import _ from "lodash";
import { reportList, viewList } from '@/config/index'
export default {
  data() {
    return {
      value1: "",
      selectedType: 'one',
      barOptionXAxis: [],
      viewOptionXAxis: [],
      seriesFirst: [],
      seriesSecond: [],
      seriesThird: [],
      viewSeriesFirst: [],
      option: {
        title: {
          text: "用户统计"
        },
        tooltip: {},
        legend: {
          data: ["销量"]
        },
        xAxis: {
          data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"]
        },
        yAxis: {},
        series: [
          {
            name: "销量",
            type: "bar",
            data: [5, 20, 36, 10, 10, 20]
          }
        ]
      },
      barOption: {
        tooltip: {
          show: true,
          trigger: 'axis',
          formatter: function (params) {//弹窗内容
            //弹窗里圆点的对应的样式，用模板字符串的方式
            let dotColor = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + params[0].color + '"></span>'
            let dotColor2 = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + params[1].color + '"></span>'
            let dotColor3 = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + params[2].color + '"></span>'
            //弹窗里的内容+样式
            return '<span style="font-size:14px;font-weight: 600;color: #20253B">' + params[0].axisValue + '</span>' + '<br>' +
              dotColor + '<span style="color: #20253B">' + params[0].seriesName + '</span>' + '：' + (params[0].data) + '<br>' +
              dotColor2 + '<span style="color: #20253B">' + params[1].seriesName + '</span>' + '：' + (params[1].data) + '<br>' +
              dotColor3 + '<span style="color: #20253B">' + params[2].seriesName + '</span>' + '：' + (params[2].data) + '<br>' +
              '全部 ' + (params[0].data + params[1].data + params[2].data)
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: { left: 0 },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '表揚',
            type: 'bar',
            stack: 'Ad',
            barWidth: 50,
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              normal: { color: '#ef4c4a' }
            },
            data: []
          },
          {
            name: '意見',
            type: 'bar',
            stack: 'Ad',
            barWidth: 50,
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              normal: { color: '#FF6F6F' }
            },
            data: []
          },
          {
            name: '投訴',
            type: 'bar',
            stack: 'Ad',
            barWidth: 50,
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              normal: { color: '#FFBABA' }
            },
            data: []
          },
        ]
      },
      bankOption: {
        legend: {
          top: "bottom"
        },
        tooltip: {
          show: true,
          trigger: 'item',
          // formatter: `{b}<br />{c} {d}`
          formatter: (params) => {
            let str = `${params.name}<br /><div>${params.value}條&nbsp&nbsp<span style="color:red">${params.percent}%</span></div>`
            return str
          }
        },
        toolbox: {
          show: false,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: "Nightingale Chart",
            type: "pie",
            radius: [50, 120],
            center: ["50%", "50%"],
            roseType: "area",
            label: {
              show: false
            },
            itemStyle: {
              borderRadius: 8
            },
            data: [
              { value: 40, name: "分行 1" },
              { value: 38, name: "分行 2" },
              { value: 32, name: "分行 3" },
              { value: 30, name: "分行 4" },
              { value: 28, name: "分行 5" },
              { value: 26, name: "分行 6" },
              { value: 22, name: "分行 7" },
              { value: 18, name: "分行 8" }
            ]
          }
        ]
      },
      viewOption: {
        xAxis: {
          type: "category",
          data: ["01", "02", "03", "04", "05", "06", "07"],
          axisLabel:{
            formatter: function (value) {             
            return value.split("-")[2]
      },
          }
        },
        yAxis: {
          type: "value"
        },
        tooltip: {
          show: true,
          formatter: function (params) {//弹窗内容
            let newValue = params.name.split("-")
            let newValueName=newValue[1]+'月'+newValue[2]+'日'            
            return '<div style="text-align:center">'+ newValueName + '<br><span style="color: #000;font-weight:bold;font-size:16px">' + (params.data)+'</span></div>'
          },
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            smooth: true,
            itemStyle: {
              normal: { color: '#FF6F6F' }
            }
          }
        ]
      },
      searchForm: {
        startTime: '',
        endTime: ''
      },
      visitorCount:0,
    };
  },
  computed: {
    categoryName() {
      let name = this.selectedType == 'one' ? 'typeOneName' : this.selectedType == 'two' ? 'typeTwoName' : 'typeThreeName'
      return name
    }
  },
  created() { },
  mounted() {
    //var bankChart = this.$echarts.init(document.getElementById("bankAmount"));

    // // 指定图表的配置项和数据
    // const {data:res}=await this.$http.get('reports/type/1')
    // if(res.meta.status!==200){
    // 	return this.$message.error("获取折线图数据失败")
    // }
    // var newReportList=_.merge(res.data,this.option)
    // // 使用刚指定的配置项和数据显示图表。
    // bankChart.setOption(this.bankOption);

    this.initEchars()

  },
  methods: {
    async getReportData() {
      return new Promise((resolve, reject) => {
        this.barOptionXAxis = []
        this.seriesFirst = []
        this.seriesSecond = []
        this.seriesThird = []
        reportList(this.searchForm).then(res => {
          if (res.status == 200) {
            let { data } = res.data
            data[this.selectedType].forEach(v => {
              this.barOptionXAxis.push(v.name)
              this.seriesFirst.push(v.type4Count ? v.type4Count : 0)
              this.seriesSecond.push(v.type1Count ? v.type1Count : 0)
              this.seriesThird.push(v.type2Count ? v.type2Count : 0)
            })
            this.$set(this.barOption.series[0], 'data', this.seriesFirst)
            this.$set(this.barOption.series[1], 'data', this.seriesSecond)
            this.$set(this.barOption.series[2], 'data', this.seriesThird)
            this.$set(this.barOption.xAxis[0], 'data', this.barOptionXAxis)
            resolve()
          }
          reject()
        }).catch(err => {
          reject()
          console.log('catch_err', err)
        })
      })

    },
    async getVisitorData() {
      return new Promise((resolve, reject) => {
        this.viewOptionXAxis = []
        this.viewSeriesFirst = []
        viewList(this.searchForm).then(res => {
          if (res.status == 200) {
            let { data } = res.data
            data.forEach(v => {
              this.viewOptionXAxis.push(v.timeStr)
              this.viewSeriesFirst.push(v.pvCount)
            })
            let count=data.reduce((acc,curVal)=>{
              return acc + curVal.pvCount
            },0)
            this.visitorCount=count
            this.$set(this.viewOption.series[0], 'data', this.viewSeriesFirst)
            this.$set(this.viewOption.xAxis, 'data', this.viewOptionXAxis)
            resolve()
          }
          reject()
        }).catch(err => {
          reject()
          console.log('catch_err', err)
        })
      })

    },
    changeType(type) {
      this.selectedType = type
      this.initEchars()
    },
    async initEchars() {
      await this.getVisitorData()
      await this.getReportData()
      var myChart = this.$echarts.init(document.getElementById("userMain"));
      var viewChart = this.$echarts.init(document.getElementById("viewAmount"));
      myChart.setOption(this.barOption);
      viewChart.setOption(this.viewOption);
    },
    setTimeRange(e) {
      this.$set(this.searchForm, 'startTime', e[0])
      this.$set(this.searchForm, 'endTime', e[1])
      this.initEchars()
    }

  }
}
</script>
<style lang="less">
.filterOption {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .filterTime {}
}

#bankAmount,
#viewAmount,
#userMain {
  width: 100%;
  height: 400px;
}

.elContent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  .elTable {
    height: 100%;

    .el-card {
      min-width: 100%;
      height: 100%;
    }
  }

  .elCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;

    .width {
      flex: 1 !important;
      margin: 0 10px 10px 0;
    }

    .card-title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .card-text {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }

      .sub-title {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }

      .card-btn {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .card-btn-item {
          width: 96px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          border: 1px solid #979797;
          margin-right: -1px;
        }

        .card-btn-item-border {
          color: #EB4343;
          border: 1px solid #EB4343;
        }

        .card-btn-item-border-left {
          color: #EB4343;
          border-left: 1px solid #EB4343;
        }
      }
    }

    .card-content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .content-item {
        flex: 1;
        text-align: center;
      }
    }

    & .el-card:last-child {
      margin-bottom: 0px;
    }
  }
}

.classifyIcon {
  display: inline-block;
  width: 15px;
  height: 14px;
  vertical-align: baseline;
  background-image: url('https://img-cdn.dakuu.cn/o_1gnucpr5o1eqj6t71h3h1t5s1mg9a.png');
  background-position: 0% 0%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
</style>
