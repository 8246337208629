<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">賬戶管理</a></el-breadcrumb-item>
      <el-breadcrumb-item>賬戶列表</el-breadcrumb-item>

    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header"
           class="clearfix">
        <el-row :gutter="20"
                class="header-search">
          <el-col :span="6">
            <el-input placeholder="请输入搜索關鍵字"
                      clearable
                      v-model="queryInfo.searchParam"
                      class="input-with-select"
                      @clear="getUserList">
              <el-button slot="append"
                         icon="el-icon-search"
                         @click="searchList"></el-button>
            </el-input></el-col>
          <el-col :span="4">
            <el-button type="danger"
                       size="mini"
                       @click="showEditDialog()"
                       v-if="role==1">新增賬號</el-button>
          </el-col>
        </el-row>
      </div>

      <el-table :data="userList"
                style="width: 100%"
                border>
        <el-table-column prop="role"
                         label="身份"
                         width="180px">
          <template slot-scope="scope">
            {{Number(scope.row.role)===1?'超級管理員':'普通用戶'}}
          </template>
        </el-table-column>
        <el-table-column prop="username"
                         label="賬號">
          <template slot-scope="scope">
            <el-row class="display-flex">
              <el-col :span="2">
                {{ scope.row.username }}
              </el-col>
              <el-col :span="4">
                <span v-if="scope.row.editDisable">{{scope.row.remark?`(${scope.row.remark})`:''}}</span>
                <el-input v-else
                          placeholder="请输入内容"
                          v-model="scope.row.remark"
                          clearable>
                </el-input>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="180px">
          <template slot-scope="scope">
            <el-button type="text"
                       size="mini"
                       class="editBtn"
                       v-if="role==1"
                       @click="showEditDialog(scope.row)">編輯</el-button>
            <el-button type="text"
                       size="mini"
                       class="editBtn"
                       @click="showRemarkEdit(scope.row, scope.$index)"><span v-if="scope.row.editDisable">備註</span><span v-else>保存</span></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.page"
                     :page-sizes="[10, 20, 40, 50]"
                     :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
      <el-dialog title="添加用户"
                 :visible.sync="addDialogVisible"
                 width="50%"
                 :before-close="handleClose"
                 @close="addDialogClosed">
        <el-form :model="addUserForm"
                 :rules="addUserRules"
                 ref="addFormRef"
                 label-width="70px"
                 class="demo-ruleForm">
          <el-form-item label="用户名"
                        prop="username">
            <el-input v-model="addUserForm.username"></el-input>
          </el-form-item>
          <el-form-item label="密码"
                        prop="password">
            <el-input v-model="addUserForm.password"></el-input>
          </el-form-item>
          <el-form-item label="邮箱"
                        prop="email">
            <el-input v-model="addUserForm.email"></el-input>
          </el-form-item>
          <el-form-item label="手机号"
                        prop="mobile">
            <el-input v-model="addUserForm.mobile"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="addUser">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="修改用户"
                 :visible.sync="editDialogVisible"
                 width="50%"
                 :before-close="handleClose"
                 @close="editDialogClosed">
        <el-form :model="editUserForm"
                 :rules="addUserRules"
                 ref="editFormRef"
                 label-width="70px"
                 class="demo-ruleForm">
          <el-form-item label="用户名">
            <el-input v-model="editUserForm.username"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="邮箱"
                        prop="email">
            <el-input v-model="editUserForm.email"></el-input>
          </el-form-item>
          <el-form-item label="手机号"
                        prop="mobile">
            <el-input v-model="editUserForm.mobile"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="updateUser">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="分配角色"
                 :visible.sync="setDialogVisible"
                 width="50%"
                 :before-close="handleClose"
                 @close="setDialogClosed">
        <el-form :model="userInfo"
                 ref="rolesFormRef"
                 label-width="70px"
                 class="demo-ruleForm">
          <el-form-item label="用户名">
            <el-input v-model="userInfo.username"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="当前角色">
            <el-input v-model="userInfo.role_name"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="用户角色">
            <el-select v-model="selectedRolesId"
                       placeholder="请选择活动区域">
              <el-option v-for="item in userRolesList"
                         :key="item.id"
                         :label="item.roleName"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="setDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="setUserRoles">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import { userList, userEdit } from '@/config/index'
export default {

  data () {
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/

      if (regEmail.test(value)) {
        return callback()
      }
      callback(new Error('请输入合法的邮箱'))
    }
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/

      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入合法的手机号'))
    }
    return {

      queryInfo: {
        searchParam: '',
        page: 1,
        pageSize: 10
      },
      input3: '',
      userList: [],
      userInfo: {},
      userRolesList: [],
      total: 0,
      selectedRolesId: '',
      addDialogVisible: false,
      editDialogVisible: false,
      setDialogVisible: false,
      editUserForm: {

      },
      addUserForm: {
        username: '',
        password: '',
        email: '',
        mobile: ''
      },
      addUserRules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }, {
            min: 3,
            max: 10,
            message: '用户名的长度在3-10个字'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }, {
            min: 6,
            max: 15,
            message: '密码的长度在6-15个字符之间',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur'
          }, { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }, { validator: checkMobile, trigger: 'blur' }
        ]

      },
      role: 2

    }
  },

  created () {
    this.getUserList()
  },
  mounted () {
    const { role } = JSON.parse(window.sessionStorage.getItem('userInfo'))
    this.role = Number(role)
  },
  methods: {
    async getUserList () {
      userList(this.queryInfo).then(res => {
        console.log('res', res.status)
        if (res.status == 200) {
          console.log(res.data)
          const { data } = res.data
          this.userList = data?.items
          this.userList.forEach((v, i, arr) => {
            this.$set(arr[i], 'editDisable', true)
          })
          this.total = data.total
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    searchList () {
      this.$set(this.queryInfo, 'page', 1)
      this.getUserList()
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getUserList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage
      this.getUserList()
    },
    async changeState (userinfo) {
      const { data: res } = await this.$http.put(`users/${userinfo.id}/state/${userinfo.mg_state}`)

      if (res.meta.status !== 200) {
        userinfo.mg_state = !userinfo.mg_state
        return this.$message.error('更新失败')
      } else {
        this.$message.success('更新成功')
      }
      this.getUserList()
    },
    handleClose (done) {
      this.addDialogVisible = false
      this.setDialogVisible = false
    },
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    addUser () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('users', this.addUserForm)

        if (res.meta.status !== 201) {
          return this.$message.error('添加用户失败')
        } else {
          this.$message.success('添加用户成功')
        }
        this.getUserList()
        this.addDialogVisible = false
      })
    },
    async showEditDialog (row) {
      this.$router.push({ path: '/users/edit', query: { row: row } })
    },
    updateUser () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          console.log('erro')
          return
        }
        const { data: res } = await this.$http.put('users/' + this.editUserForm.id, {
          email: this.editUserForm.email,
          mobile: this.editUserForm.mobile
        })

        if (res.meta.status !== 200) {
          return this.$message.error('更新用户信息失败')
        } else {
          this.$message.success('更新用户信息成功')
        }
        this.getUserList()
        this.editDialogVisible = false
      })
    },
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    async deleteUser (id) {
      const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('111')
        const { data: res } = this.$http.delete('users/' + id)

        //       		if(res.meta.status!==200){
        // 	return this.$message.error("删除用户信息失败")
        // }
        this.$message({
          type: 'success',
          message: '删除成功!'
        })

        this.getUserList()
      }).catch(() => {
        console.log('222')
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async showSetDialog (userInfo) {
      this.userInfo = userInfo
      const { data: res } = await this.$http.get('roles')
      if (res.meta.status !== 200) {
        return this.$message.error('获取角色列表失败')
      } else {
        this.$message.success('获取角色列表成功')
      }
      this.userRolesList = res.data
      this.setDialogVisible = true
    },
    async setUserRoles () {
      if (!this.selectedRolesId) {
        return this.$message.error('请选择要分配的角色')
      }
      const { data: res } = await this.$http.put(`users/${this.userInfo.id}/role`, { rid: this.selectedRolesId })
      if (res.meta.status !== 200) {
        return this.$message.error('修改角色失败')
      } else {
        this.$message.success('修改角色成功')
      }

      this.setDialogVisible = true
      this.getUserList()
    },
    setDialogClosed () {
      this.selectedRolesId = '',
        this.userInfo = {}
    },
    showRemarkEdit (row, index) {
      const editStatus = row.editDisable
      this.$set(this.userList[index], 'editDisable', !editStatus)
      if (!editStatus) {
        const { email, id, name, remark, username } = row
        console.log('保存')
        userEdit({ id, name, username, remark, email }).then(res => {
          console.log('res', res)
          if (res.status == 200) {
            this.$message.success('修改成功')
            this.getUserList()
          }
        }).catch(err => {
          console.log('catch_err', err)
        })
      }
    }

  }
}
</script>
<style lang="less">
.header-search {
  height: 28px;

  .input-with-select .el-input__inner {
    height: 30px !important;
  }
}
</style>
