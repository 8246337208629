<template>
  <el-card>
    <div slot="header"
         class="clearfix">
      <!-- <el-row :gutter="20">
					<el-col :span="8">
						<el-input placeholder="请输入内容" clearable v-model="queryInfo.query" class="input-with-select" @clear="getGoodsList">
							<el-button slot="append" icon="el-icon-search" @click="getGoodsList"></el-button>
						</el-input></el-col>
						<el-col :span="4">
							<el-button type="primary" @click="addGoodsPage">添加商品</el-button>
						</el-col>
				</el-row> -->
      <el-form ref="form"
               :inline="true"
               label-width="80px"
               :model="searchForm"
               @submit.native.prevent>
        <el-row>
          <el-col :span="6">
            <el-form-item label="一級分類">
              <el-select v-model="searchForm.parentId"
                         clearable
                         placeholder="请选择"
                         @change="(e,type)=>{selectClassifyType(e,2)}"
                         @clear="clearParentId">
                <el-option v-for="item in oneOptions"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
        <el-form-item label="二級分類">
          <el-select v-model="searchForm.typeTwoId" :disabled="checkTwoType" @change="(e,type)=>{selectClassifyType(e,3)}"  placeholder="请选择">
          <el-option
            v-for="item in twoOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        </el-form-item>
        </el-col> -->
          <el-col :span="6">
            <el-form-item>
              <el-button native-type="submit"
                         type="danger"
                         size="mini"
                         @click="searchSubmit()">
                查询
              </el-button>
              <el-button native-type="submit"
                         @click="createActivity()"
                         size="mini">
                重置
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table :data="goodsList"
              style="width: 100%"
              border>
      <el-table-column prop="oneName"
                       label="所屬一級"
                       width="200px">
        <template slot="header"
                  slot-scope="scope">
          <div class="headerClassify">
            所屬一級
            <!-- <el-image
           class="classifyIcon"
           src="https://img-cdn.dakuu.cn/o_1gnucpr5o1eqj6t71h3h1t5s1mg9a.png"
            fit="contain">
            </el-image> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name"
                       label="名稱">
      </el-table-column>
      <el-table-column prop="orderNo"
                       label="排序"
                       width="220">
        <template slot-scope="scope">
          <!-- <div class="sortData">{{scope.row.orderNo}}</div> -->
          <el-input-number v-model="scope.row.orderNo"
                           @change="(currentValue,oldValue)=>handleChange(scope.row,currentValue,oldValue)"
                           :min="0"
                           size="mini"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       width="180px">
        <template slot-scope="scope">
          <el-button type="text"
                     size="mini"
                     @click="showEditDialog(scope.row)"
                     class="editBtn">編輯</el-button>
          <el-button type="text"
                     size="mini"
                     @click="showClassify(1, scope.row.id)"
                     class="editDis"
                     v-if="scope.row.status==0">上架</el-button>
          <el-button type="text"
                     size="mini"
                     @click="showClassify(0,scope.row.id)"
                     class="editBtn"
                     v-else>下架</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="queryInfo.page"
                   :page-sizes="[10, 20, 40, 50]"
                   :page-size="queryInfo.pageSize"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total">
    </el-pagination>
  </el-card>
</template>
<script>
import { operateClassify, classifyList, classifyEditInfo, classifySelectList } from '@/config/index'
export default {
  props: {
    gameid: {
      type: [Number, String],
      required: false,
      default: '',
    },
    classifyType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data () {
    return {
      queryInfo: {
        parentId: '',
        page: 1,
        pageSize: 10
      },
      total: 0,
      goodsList: [],
      ruleForm: {
        id: '',
        name: '',
        type: 2,
        iconUrl: '',
        oneId: '',
        oneName: '',
        twoId: '',
        twoName: '',
        orderNo: 0
      },
      searchForm: {
        parentId: '',
        type: 2
      },
      oneOptions: [],
      twoOptions: [],
      threeOptions: [],

    }
  },
  computed: {
    opinionStatusText () {
      return (val) => {
        switch (val) {
          case 1:
            return {
              color: '#52C41A',
              text: '已處理'
            }
          case 2:
            return {
              color: '#FFB333',
              text: '處理中'
            }
          default:
            return {
              color: '#FF827E',
              text: '未處理'
            }
        }
      }
    },
    checkTwoType () {
      if (this.searchForm.parentId && this.twoOptions.length != 0) {
        return false
      } else {
        return true
      }
    },
    checkThreeType () {
      if (this.searchForm.parentId && this.threeOptions.length != 0) {
        return false
      } else {
        return true
      }

    },
  },
  created () {
    this.getGoodsList();
    this.getClassifySelectList(1)

  },
  methods: {
    async getGoodsList () {

      await classifyList(this.queryInfo, this.searchForm).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          let { data } = res.data.data
          this.goodsList = data?.items;
          this.total = data.total;
        }
      }).catch(err => {
        console.log('catch_err', err)
      })

    },
    showEditDialog (row) {
      this.$router.push({ path: '/categories/edit', query: { classifyType: this.classifyType, row: row } })
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize;
      this.getGoodsList();

    },
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage;
      this.getGoodsList();

    },

    showClassify (status, id) {
      let params = {
        type: this.classifyType == 'first' ? 1 : this.classifyType == 'second' ? 2 : 3,
        id: id,
        status
      }
      operateClassify(params).then(res => {
        console.log('res', res)
        if (res.status == 200) {
          this.$message.success(`${status == 0 ? '下架' : '上架'}成功`)
          this.getGoodsList()
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    async handleChange (row, currentValue, oldValue) {
      let formKey = Object.getOwnPropertyNames(this.ruleForm)
      for (var key in row) {
        formKey.includes(key) ? this.$set(this.ruleForm, key, row[key]) : ''
      }
      let newRuleForm = {
        orderNo: currentValue,
        ...this.ruleForm,
      }
      await classifyEditInfo(newRuleForm).then(res => {
        if (res.status == 200) {
          this.$message.success("修改排序成功")
          this.getGoodsList()
        }
      }).catch(err => {
        console.log('catch_err', err)
      })

    },
    selectClassifyType (e, type) {

      console.log('0201', e, type)
      if (type == 2) {
        this.clearParentId()
      } else {
        this.$set(this.searchForm, 'typeThreeId', '')
        this.threeOptions = []
      }
      this.getClassifySelectList(type, e)
    },
    getClassifySelectList (type, parentId) {
      let params = {
        type,
        parentId: type == 1 ? '' : parentId
      }
      classifySelectList(params).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (type == 1) {
            this.oneOptions = data.data
          } else if (type == 2 && data.currentType == 2) {
            //this.clearParentId()
            this.twoOptions = data.data
          } else {
            //this.clearParentId()
            this.threeOptions = []
          }
          console.log('res_op', data.currentType)
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    clearParentId () {
      this.$set(this.searchForm, 'typeTwoId', '')
      this.$set(this.searchForm, 'typeThreeId', '')
      this.twoOptions = []
      this.threeOptions = []
    },
    searchSubmit () {
      console.log('0201', this.searchForm)
      this.queryInfo.page = 1
      this.queryInfo = { ...this.queryInfo, ...this.searchForm }
      this.getGoodsList();
    },
    createActivity () {
      this.$set(this, 'searchForm', this.$options.data().searchForm)
      this.$set(this, 'queryInfo', this.$options.data().queryInfo)
      this.getGoodsList()
    }


  }
}
</script>
<style lang="less">
.opinionTypeCircle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ffb333;
  margin-right: 8px;
  border-radius: 50%;
}

.el-card__header {
  border-bottom: none !important;
}

.textRight {
  text-align: right;
}

.headerClassify {
  display: flex;
  justify-content: center;
  align-items: center;

  .classifyIcon {
    display: block;
    width: 19px;
    height: 17px;
  }
}

.sortData {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 30px;
}
</style>