//const $ENV = "DEV"; // 测试服
const $ENV = "PROD"; // 正式服
const currentVersion = "V230529-1";
function getContext() {
  var url = window.location.href;
  var hostname = window.location.host;
  var ret = "/";
  if (url.indexOf("http://") != -1 || url.indexOf("https://") != -1) {
    let newUrl = url.replace(new RegExp("http://", "g"), "");
    //let startIndex = newUrl.indexOf("/");
    let endIndex = newUrl.indexOf("#");
    let context = newUrl.substring(0, endIndex);

    if (context) {
      ret = context + "api/api";
    } else {
      ret = "/api/api";
    }
  }
  return ret;
}
let newApi = getContext();
const baseUrl = $ENV === "DEV" ? process.env.VUE_APP_HOST1 : newApi;

console.log("0529", newApi);
module.exports = {
  $ENV,
  baseUrl,
  currentVersion
};
