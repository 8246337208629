/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import LoginApi from '../components/LoginApi.vue'
import LoginH5 from '../components/LoginH5.vue'
import LoginTip from '../components/LoginTip.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
// import User from '../components/user/user.vue'
// import Cate from '../components/good/cate.vue'
// import List from '../components/good/list.vue'
// import Add from '../components/good/add.vue'
// import Order from '../components/order/order.vue'
// import Report from '../components/report/report.vue'
import Report from '../components/report/reportEcharts.vue'
import List from '../components/good/complaintList.vue'
import Add from '../components/good/complaintEdit.vue'
import Cate from '../components/classification/classifyList.vue'
import CateAdd from '../components/classification/classifyEdit.vue'
import Order from '../components/order/noticeList.vue'
import User from '../components/user/userList.vue'
import UserAdd from '../components/user/userEdit.vue'
import mineEdit from '../components/user/mineDetail.vue'

Vue.use(VueRouter)

const router= new VueRouter({
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: LoginTip },
    { path: '/home', 
	    component: Home,
	    redirect:'/reports',
  	    children:[
  	    {
  	    	path: '/welcome', 
  	    	component: Welcome
  	    },
        {
          path: '/users', 
          name:'賬戶列表',
          component: User
        },
        {
          path: '/categories',
          name:'分類列表', 
          component: Cate
        },
        {
          path: '/goods', 
          name:'工單列表',
          component: List
        },
        {
          path: '/good/add', 
          name:'工單詳情',
          component: Add
        },
        {
          path: '/orders', 
          name:'通知管理', 
          component: Order
        },
        {
          path: '/reports', 
          name:'數據統計', 
          component: Report
        },
        {
          path: '/categories/edit', 
          component: CateAdd,
          name:'新增/編輯分類', 
        },
        {
          path: '/users/edit', 
          name:'新增/編輯賬號', 
          component: UserAdd
        },
        {
          path: '/mine', 
          name:'編輯賬戶', 
          component: mineEdit
        },
      ] 
    }
  ]
})
router.beforeEach((to,from,next)=>{
   if(to.path==='/login') return next();
   const tokenStr=window.sessionStorage.getItem('token')
   if(!tokenStr) return next('/login')
   	next()
})
export default router;