<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">賬號管理</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">{{
          userId ? "編輯賬號" : "新增賬號"
        }}</a></el-breadcrumb-item>
      <!-- <el-breadcrumb-item>商品分类</el-breadcrumb-item> -->
    </el-breadcrumb>
    <el-card>
      <div class="centerBox">
        <el-row class="centerForm">
          <el-form :model="editUserForm"
                   :rules="addUserRules"
                   ref="editFormRef"
                   label-width="80px"
                   class="demo-ruleForm">
            <el-form-item label="賬號"
                          prop="username">
              <el-input v-model="editUserForm.username"
                        placeholder="由字母/數字組成，不超過25個字符"></el-input>
            </el-form-item>
            <el-form-item label="姓名"
                          prop="name">
              <el-input v-model="editUserForm.name"
                        placeholder="不超過25個字符"></el-input>
            </el-form-item>
            <el-form-item label="邮箱"
                          prop="email">
              <el-input v-model="editUserForm.email"
                        placeholder="請輸入"></el-input>
            </el-form-item>
          </el-form>
        </el-row>
        <div>
          <el-button v-if="userId"
                     @click="handelEditDialog">修改賬戶密碼</el-button>
          <el-button type="danger"
                     @click="submitForm('editUserForm')">{{
            userId ? "確認修改" : "新增"
          }}</el-button>
        </div>
      </div>
    </el-card>
    <editPsdDialog ref="editPsdInfo"></editPsdDialog>
  </div>
</template>

<script>
import { logoutText, editMyself } from '@/config/index'
import secondList from '../classification/secondList.vue'
import editPsdDialog from './mineEdit.vue'
export default {
  components: { secondList, editPsdDialog },
  data () {
    return {
      userId: null,
      classifyType: 1,
      editUserForm: {
        id: '',
        name: '',
        username: '',
        email: ''
      },
      addUserRules: {
        username: [
          { required: true, message: '請輸入賬號', trigger: 'blur' },
          { min: 3, max: 25, message: '长度在 3 到 25 个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '請輸入姓名', trigger: 'blur' },
          { min: 3, max: 25, message: '长度在 3 到 25 个字符', trigger: 'blur' }
        ],
        email: [{ required: true, message: '請輸入郵箱', trigger: 'blur' }]
      },
      type: ''
    }
  },
  computed: {
    qiniuDomain () {
      return function (region) {
        switch (region) {
          case '10':
            return 'https://upload.qiniup.com'
          case '20':
            return 'https://upload-z1.qiniup.com'
          case '30':
            return 'https://upload-z2.qiniup.com'
          case '40':
            return 'https://upload-na0.qiniup.com'
          case '50':
            return 'https://upload-as0.qiniup.com'
          default:
            return 'https://upload-z1.qiniup.com'
        }
      }
    }
  },
  created () { },
  mounted () {
    const { row, type } = this.$route.query
    if (row?.id) {
      this.userId = row.id
      this.type = type
      console.log('type', this.type)
      const formKey = Object.getOwnPropertyNames(this.editUserForm)
      for (var key in row) {
        formKey.includes(key)
          ? this.$set(this.editUserForm, key, row[key])
          : ''
      }
    }
  },
  methods: {
    submitForm () {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          const newEditUserForm = {
            name: this.editUserForm.name,
            username: this.editUserForm.username,
            email: this.editUserForm.email
          }

          if (this.userId) {
            this.$set(newEditUserForm, 'id', this.userId)
            editMyself(newEditUserForm)
              .then(res => {
                if (res.status == 200) {
                  if (res.data.code == '0000') {
                    this.formInit()
                    if (this.type == 'mine') {
                      this.$message.success('修改成功，请重新登录！')
                      this.logout()
                    } else {
                      this.$message.success('修改失敗')
                      this.$router.push('/users')
                    }
                  } else {
                    this.$message.error(res.data.info)
                  }
                }
              })
              .catch(err => {
                console.log('catch_err', err)
              })
          }
        } else {
          return this.$message.error('请填写必要的表单项')
        }
      })
    },
    formInit () {
      this.$refs.editFormRef.resetFields()
      this.$set(this, 'editUserForm', this.$options.data().editUserForm)
      this.$set(this, 'userId', this.$options.data().userId)
    },
    resetForm () {
      this.formInit()
      this.$router.push('/users')
    },
    handelEditDialog () {
      this.$refs.editPsdInfo.showEdit(this.userId)
    },
    async logout () {
      try {
        const res = await logoutText()
        if (res.status == 200) {
          window.sessionStorage.clear()
          this.$router.push('/login')
        } else {
          throw new Error(res.msg)
        }
      } catch (error) {
        console.error('logout', error)
      }
    }

  }
}
</script>

<style lang="less" scoped>
.centerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .centerForm {
    width: 70%;
  }
}
</style>
