<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">工單管理</a></el-breadcrumb-item>
      <el-breadcrumb-item>工單列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div slot="header"
           class="clearfix">
        <!-- <el-row :gutter="20">
					<el-col :span="8">
						<el-input placeholder="请输入内容" clearable v-model="queryInfo.query" class="input-with-select" @clear="getGoodsList">
							<el-button slot="append" icon="el-icon-search" @click="getGoodsList"></el-button>
						</el-input></el-col>
						<el-col :span="4">
							<el-button type="primary" @click="addGoodsPage">添加商品</el-button>
						</el-col>
				</el-row> -->
        <el-form ref="form"
                 :inline="true"
                 label-width="80px"
                 :model="searchForm"
                 @submit.native.prevent>
          <el-row>
            <el-col :span="6">
              <el-form-item label="ID">
                <el-input v-model="searchForm.userId"
                          placeholder="id"
                          :min="0" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="一級分類">
                <el-select v-model="searchForm.typeOneId"
                           clearable
                           placeholder="请选择"
                           @change="(e,type)=>{selectClassifyType(e,2)}"
                           @clear="clearParentId">
                  <el-option v-for="item in oneOptions"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="二級分類">
                <el-select v-model="searchForm.typeTwoId"
                           :disabled="checkTwoType"
                           @change="(e,type)=>{selectClassifyType(e,3)}"
                           placeholder="请选择">
                  <el-option v-for="item in twoOptions"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="三級分類">
                <el-select v-model="searchForm.typeThreeId"
                           :disabled="checkThreeType"
                           placeholder="请选择">
                  <el-option v-for="item in threeOptions"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="意見/投訴/表揚"
                            label-width='120px'>
                <el-select v-model="searchForm.opinionType"
                           placeholder="请选择">
                  <el-option v-for="item in typeList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="反饋時間">
                <el-date-picker v-model="opinionTimeRange"
                                type="daterange"
                                value-format="timestamp"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="setTimeRange"
                                :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="處理狀態">
                <el-select v-model="searchForm.opinionStatus"
                           placeholder="请选择">
                  <el-option v-for="item in statusList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="textRight">
            <el-form-item>
              <el-button native-type="submit"
                         type="danger"
                         size="mini"
                         @click="searchSubmit()">
                查询
              </el-button>
              <el-button native-type="submit"
                         @click="createActivity()"
                         size="mini">
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </div>

      <el-table :data="goodsList"
                v-loading="tableLoading"
                style="width: 100%"
                border>
        <!-- <el-table-column
				 type="index">
			    </el-table-column> -->
        <el-table-column prop="userId"
                         label="ID"
                         width="150">
        </el-table-column>
        <el-table-column prop="typeOneName"
                         label="一級分類"
                         width="180">
          <template slot="header"
                    slot-scope="scope">
            <div class="headerClassify">
              一級分類<!-- <span class="classifyIcon"></span> -->
              <!-- <el-image
           class="classifyIcon"
           src="https://img-cdn.dakuu.cn/o_1gnucpr5o1eqj6t71h3h1t5s1mg9a.png"
            fit="contain">
            </el-image> -->
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="typeTwoName"
                         label="二級分類"
                         width="180">
          <template slot="header"
                    slot-scope="scope">
            <div class="headerClassify">
              二級分類
              <!-- <el-image
           class="classifyIcon"
           src="https://img-cdn.dakuu.cn/o_1gnucpr5o1eqj6t71h3h1t5s1mg9a.png"
            fit="contain">
            </el-image> -->
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="typeThreeName"
                         label="三級分類">
          <template slot="header"
                    slot-scope="scope">
            <div class="headerClassify">
              三級分類
              <!-- <el-image
           class="classifyIcon"
           src="https://img-cdn.dakuu.cn/o_1gnucpr5o1eqj6t71h3h1t5s1mg9a.png"
            fit="contain">
            </el-image> -->
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="opinionType"
                         label="意見/投訴/表揚">
          <template slot-scope="scope">
            <span v-if="scope.row.opinionType==1">意見反饋</span>
            <span v-else-if="scope.row.opinionType==2"
                  style="color:#E35151">投訴建議</span>
            <span v-else>表揚</span>
          </template>
        </el-table-column>
        <el-table-column prop="opinionTime"
                         label="反饋時間"
                         sortable>
          <template slot-scope="scope">
            {{scope.row.opinionTime|dateFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="opinionType"
                         label="狀態">
          <template slot-scope="scope">
            <span class="opinionTypeCircle"
                  :style="{background:opinionStatusText(scope.row.opinionStatus).color}"></span>{{opinionStatusText(scope.row.opinionStatus).text}}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="180px">
          <template slot-scope="scope">
            <el-button type="text"
                       size="mini"
                       @click="showEditDialog(scope.row)"
                       class="editBtn">編輯</el-button>
            <!-- <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteGoods(scope.row.goods_id)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.page"
                     :page-sizes="[10, 20, 40, 50]"
                     :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
      <el-dialog title="添加商品"
                 :visible.sync="addDialogVisible"
                 width="50%"
                 @close="addDialogClosed">
        <el-form :model="addGoodsForm"
                 :rules="addGoodsRules"
                 ref="addGoodsFormRef"
                 label-width="70px"
                 class="demo-ruleForm">
          <el-form-item label="用户名"
                        prop="goods_name">
            <el-input v-model="addGoodsForm.goods_name"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="addGoodsForm.goods_price"></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="addGoodsForm.goods_price"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="addGoodsForm.goods_weight"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="addGoods">确 定</el-button>
        </span>
      </el-dialog>
      <!-- <el-dialog
			  title="修改商品"
			  :visible.sync="editDialogVisible"
			  width="50%"
			  :before-close="handleClose"
			  @close="editDialogClosed">
			  <el-form :model="editGoodsForm" :rules="addUserRules" ref="editGoodsFormRef" label-width="70px" class="demo-ruleForm">
				  <el-form-item label="用户名">
				    <el-input v-model="editUserForm.username" disabled></el-input>
				  </el-form-item>
				  <el-form-item label="邮箱" prop="email">
				    <el-input v-model="editUserForm.email"></el-input>
				  </el-form-item>
				  <el-form-item label="手机号" prop="mobile">
				    <el-input v-model="editUserForm.mobile"></el-input>
				  </el-form-item>
				</el-form>
			  <span slot="footer" class="dialog-footer">
			    <el-button @click="editDialogVisible = false">取 消</el-button>
			    <el-button type="primary" @click="updateGoods">确 定</el-button>
			  </span>
			</el-dialog> -->

    </el-card>
  </div>
</template>
<script>
import { suggestList, classifySelectList } from '@/config/index'
export default {
  data () {
    return {
      queryInfo: {
        page: 1,
        pageSize: 10
      },
      typeOneIdOptions: [{
        value: 0,
        label: '一级分类'
      }],
      typeTwoIdOptions: [{
        value: 0,
        label: '二级分类'
      }],
      typeThreeIdOptions: [{
        value: 0,
        label: '三级分类'
      }],
      typeList: [
        {
          value: 1,
          label: '意見反饋'
        },
        {
          value: 2,
          label: '投訴建議'
        },
        {
          value: 4,
          label: '表揚'
        }
      ],
      statusList: [
        {
          value: 1,
          label: '未處理'
        },
        {
          value: 2,
          label: '處理中'
        },
        {
          value: 3,
          label: '已處理'
        }
      ],
      searchForm: {
        userId: '',
        typeOneId: '',
        typeTwoId: '',
        typeThreeId: '',
        opinionType: '',
        startOpinionTime: '',
        endOpinionTime: '',
        opinionStatus: ''
      },
      total: 0,
      goodsList: [],
      addDialogVisible: false,
      editDialogVisible: false,
      addGoodsForm: {
        goods_name: '',
        goods_price: '',
        goods_weight: ''

      },
      editGoodsForm: {
        goods_name: '',
        goods_price: '',
        goods_weight: ''
      },
      addGoodsRules: {
        goods_name: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }, {
            min: 3,
            max: 10,
            message: '用户名的长度在3-10个字'
          }
        ]

      },
      statusVal: '',
      oneOptions: [],
      twoOptions: [],
      threeOptions: [],
      opinionTimeRange: [],
      tableLoading: false

    }
  },
  computed: {
    opinionStatusText () {
      return (val) => {
        switch (val) {
          case 3:
            return {
              color: '#52C41A',
              text: '已處理'
            }
          case 2:
            return {
              color: '#FFB333',
              text: '處理中'
            }
          default:
            return {
              color: '#FF827E',
              text: '未處理'
            }
        }
      }
    },
    checkTwoType () {
      if (this.searchForm.typeOneId && this.twoOptions.length != 0) {
        return false
      } else {
        return true
      }
    },
    checkThreeType () {
      if (this.searchForm.typeOneId && this.threeOptions.length != 0) {
        return false
      } else {
        return true
      }
    }
  },
  created () {
    this.getGoodsList()
    this.getClassifySelectList(1)
  },
  methods: {
    async getGoodsList () {
      this.tableLoading = true
      suggestList(this.queryInfo).then(res => {
        console.log('res', res.status)
        if (res.status == 200) {
          console.log(res.data)
          const { data } = res.data
          this.goodsList = data?.items
          this.total = data.total
          setTimeout(() => {
            this.tableLoading = false
          }, 500)
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    showEditDialog (row) {
      this.$router.push({ path: '/good/add', query: { row: row } })
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getGoodsList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage
      this.getGoodsList()
    },
    addDialogClosed () {

    },
    editDialogClosed () {

    },
    addGoods () {

    },
    async deleteGoods (goods_id) {
      const confirmResult = await this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async (data) => {
        const { data: res } = await this.$http.delete(`goods/${goods_id}`)
        if (res.meta.status !== 200) {
          return this.$message.error('删除商品列表失败')
        } else {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
        this.getGoodsList()
      }).catch((err) => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    updateGoods () {

    },
    addGoodsPage () {
      this.$router.push('/good/add')
    },
    searchSubmit () {
      this.queryInfo.page = 1
      this.queryInfo = { ...this.queryInfo, ...this.searchForm }
      this.getGoodsList()
    },
    selectClassifyType (e, type) {
      console.log('0201', e, type)
      if (type == 2) {
        this.clearParentId()
      } else {
        this.$set(this.searchForm, 'typeThreeId', '')
        this.threeOptions = []
      }
      this.getClassifySelectList(type, e)
    },
    getClassifySelectList (type, parentId) {
      const params = {
        type,
        parentId: type == 1 ? '' : parentId
      }
      classifySelectList(params).then(res => {
        if (res.status == 200) {
          const { data } = res.data
          if (type == 1) {
            this.oneOptions = data.data
          } else if (type == 2 && data.currentType == 2) {
            // this.clearParentId()
            this.twoOptions = data.data
          } else {
            // this.clearParentId()
            this.threeOptions = data.data
          }
          console.log('res_op', data.currentType)
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    clearParentId () {
      this.$set(this.searchForm, 'typeTwoId', '')
      this.$set(this.searchForm, 'typeThreeId', '')
      this.twoOptions = []
      this.threeOptions = []
    },
    setTimeRange (e) {
      console.log('0202', e)
      this.$set(this.searchForm, 'startOpinionTime', e[0])
      this.$set(this.searchForm, 'endOpinionTime', e[1])
    },
    createActivity () {
      this.$set(this, 'searchForm', this.$options.data().searchForm)
      this.$set(this, 'queryInfo', this.$options.data().queryInfo)
      this.opinionTimeRange = []
      this.getGoodsList()
    }

  }
}
</script>
<style lang="less">
.opinionTypeCircle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ffb333;
  margin-right: 8px;
  border-radius: 50%;
}
.el-card__header {
  border-bottom: none !important;
}
.textRight {
  text-align: right;
}
.headerClassify {
  display: flex;
  justify-content: center;
  align-items: center;
  .classifyIcon {
    display: block;
    width: 19px;
    height: 17px;
  }
}
</style>
