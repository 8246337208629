<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">分類管理</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">分類列表</a></el-breadcrumb-item>
      <!-- <el-breadcrumb-item>商品分类</el-breadcrumb-item> -->
    </el-breadcrumb>
    <el-row class="classifyBox">
      <el-col :span="24">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="一級分類" name="first">
            <oneList :classifyType="activeName"></oneList>
          </el-tab-pane>
          <el-tab-pane label="二級分類" name="second">
            <secondList :classifyType="activeName"></secondList>
          </el-tab-pane>
          <el-tab-pane label="三級分類" name="third">
            <thirdList :classifyType="activeName"></thirdList>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <div class="addClassifyBtn">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-button type="danger" @click="showEditDialog" size="small"
              >添加分类</el-button
            >
          </el-col>
        </el-row>
      </div>
    </el-row>

    <el-dialog
      title="添加分类"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <el-form
        :model="addCateForm"
        ref="addCateRef"
        :rules="addCateRules"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="分类名称" prop="cat_name">
          <el-input v-model="addCateForm.cat_name"></el-input>
        </el-form-item>
        <el-form-item label="父级分类">
          <el-cascader
            v-model="selectedKeys"
            :options="cateList"
            :props="cascaderProps"
            clearable
            @change="handleChange"
          >
          </el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCategories">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import oneList from "./oneList.vue";
import secondList from "./secondList.vue";
import thirdList from "./thirdList.vue";
export default {
  components: {
    oneList,
    secondList,
    thirdList
  },
  data() {
    return {
      addDialogVisible: false,
      categoriesList: [],
      cateList: [],
      addCateForm: {
        cat_name: "",
        cat_pid: 0,
        cat_level: 0
      },
      editCateForm: {},
      queryInfo: {
        type: 3,
        pagenum: 1,
        pagesize: 5
      },
      total: 0,
      columns: [
        {
          label: "分类名称",
          prop: "cat_name",
          minWidth: "50px"
        },
        {
          label: "是否有效",
          prop: "cat_deleted",
          type: "template",
          template: "selected"
        },
        {
          label: "排序",
          prop: "cat_level",
          minWidth: "200px",
          type: "template",
          template: "order"
        },
        {
          label: "操作",
          minWidth: "200px",
          type: "template",
          template: "operation"
        }
      ],
      addCateRules: {
        cat_name: [
          {
            required: true,
            message: "请输入分类名",
            trigger: "blur"
          },
          {
            min: 3,
            max: 10,
            message: "用户名的长度在3-10个字"
          }
        ]
      },
      cascaderProps: {
        value: "cat_id",
        label: "cat_name",
        children: "children",
        expandTrigger: "hover",
        checkStrictly: true
      },
      selectedKeys: [],
      activeName: "first"
    };
  },
  created() {
    //this.getCategoriesList();
  },
  methods: {
    async getCategoriesList() {
      const { data: res } = await this.$http.get("categories", {
        params: this.queryInfo
      });
      if (res.meta.status !== 200) {
        return this.$message.error("获取分类列表失败");
      } else {
        this.$message.success("获取分类列表成功");
      }
      this.categoriesList = res.data.result;
      this.total = res.data.total;
    },
    handleClose(done) {
      this.addDialogVisible = false;
    },
    addDialogClosed() {
      this.$refs.addCateRef.resetFields();
      this.selectedKeys = [];
      this.addCateForm.cat_pid = 0;
      this.addCateForm.cat_level = 0;
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getCategoriesList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getCategoriesList();
    },
    showAddDialog() {
      this.getCateList();
      this.addDialogVisible = true;
    },
    showEditDialog() {
      this.$router.push({path:'/categories/edit',query:{classifyType:this.activeName}})
    },
    deleteUser() {},
    addCategories() {
      this.$refs.addCateRef.validate(async valid => {
        if (!valid) return;
        const { data: res } = await this.$http.post(
          "categories",
          this.addCateForm
        );

        if (res.meta.status !== 201) {
          return this.$message.error("添加分类失败");
        } else {
          this.$message.success("添加分类成功");
        }
        this.getCategoriesList();
        this.addDialogVisible = false;
      });
    },
    async getCateList() {
      const { data: res } = await this.$http.get("categories", {
        params: {
          type: 2
        }
      });
      if (res.meta.status !== 200) {
        return this.$message.error("获取分类列表失败");
      } else {
        this.$message.success("获取分类列表成功");
      }
      this.cateList = res.data;
    },
    handleChange(value) {
      if (value.length > 0) {
        this.addCateForm.cat_pid = value[value.length - 1];
        this.addCateForm.cat_level = value.length;
        return;
      } else {
        this.addCateForm.cat_pid = 0;
        this.addCateForm.cat_level = 0;
      }
    },
    handleClick(){
      
    }
  }
};
</script>
<style lang="less" scoped>
.classifyBox {
  position: relative;
}
.addClassifyBtn {
  position: absolute;
  right: 0px;
  top: 0;
}
</style>
