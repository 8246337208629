<template>
  <el-dialog title="修改密碼"
             :visible.sync="editDialogVisible"
             width="50%"
             @close="resetForm">
    <el-form :model="editUserForm"
             :rules="addUserRules"
             ref="editFormRef"
             label-width="120px"
             class="demo-ruleForm">
      <el-form-item :label="'原密碼'"
                    prop="oldPwd">
        <el-input v-model="editUserForm.oldPwd"
                  type="password"
                  placeholder="8～16個字符，必須包含大小寫字母,數字和特殊字符"></el-input>
      </el-form-item>
      <el-form-item :label="'修改密碼'"
                    prop="newPwd">
        <el-input v-model="editUserForm.newPwd"
                  type="password"
                  placeholder="8～16個字符，必須包含大小寫字母,數字和特殊字符"></el-input>
      </el-form-item>
      <el-form-item :label="'確認密碼'"
                    prop="confirmNewPwd">
        <el-input v-model="editUserForm.confirmNewPwd"
                  type="password"
                  placeholder="8～16個字符，必須包含大小寫字母,數字和特殊字符"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="resetForm">取 消</el-button>
      <el-button type="danger"
                 @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { editPsd, logoutText } from '@/config/index'
export default {
  data () {
    var validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填寫確認密码!'))
      } else if (value !== this.editUserForm.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      userId: null,
      editUserForm: {
        oldPwd: '',
        newPwd: '',
        confirmNewPwd: ''
      },
      addUserRules: {
        oldPwd: [
          { required: true, message: '請輸入密碼', trigger: 'blur' },
          { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' }
        ],
        newPwd: [
          { required: true, message: '請輸入密碼', trigger: 'blur' },
          { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' }
        ],
        confirmNewPwd: [
          { required: true, trigger: 'blur', validator: validateConfirmPassword },
          { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' }
        ]
      },
      type: '',
      editDialogVisible: false
    }
  },

  mounted () {
    this.formInit()
  },
  methods: {
    showEdit (id) {
      this.userId = id
      this.editDialogVisible = true
    },
    submitForm () {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          const newEditUserForm = {
            oldPwd: this.$Base64.encode(this.editUserForm.oldPwd),
            newPwd: this.$Base64.encode(this.editUserForm.newPwd),
            confirmNewPwd: this.$Base64.encode(this.editUserForm.confirmNewPwd)
          }

          if (this.userId) {
            this.$set(newEditUserForm, 'id', this.userId)
            editPsd(newEditUserForm)
              .then(res => {
                if (res.status == 200) {
                  if (res.data.code == '0000') {
                    this.formInit()
                    this.$message.success('修改成功，请重新登录！')
                    this.logout()
                  } else {
                    this.$message.error(res.data.info)
                  }
                }
              })
              .catch(err => {
                console.log('catch_err', err)
              })
          }
        } else {
          return this.$message.error('请填写必要的表单项')
        }
      })
    },
    formInit () {
      this.$refs.editFormRef.resetFields()
      this.$set(this, 'editUserForm', this.$options.data().editUserForm)
      this.$set(this, 'userId', this.$options.data().userId)
    },
    resetForm () {
      this.formInit()
      this.editDialogVisible = false
    },
    async logout () {
      try {
        const res = await logoutText()
        if (res.status == 200) {
          window.sessionStorage.clear()
          this.$router.push('/login')
        } else {
          throw new Error(res.msg)
        }
      } catch (error) {
        console.error('logout', error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.centerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .centerForm {
    width: 70%;
  }
}
</style>
