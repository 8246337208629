<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">通知管理</a></el-breadcrumb-item>
      <!-- <el-breadcrumb-item>订单列表</el-breadcrumb-item> -->
    </el-breadcrumb>
    <el-row style="text-align:right;margin-bottom:10px">
      <el-button type="danger"
                 @click="editAddrDialog"
                 size="small">新增郵箱</el-button>
      <el-button v-if="multipleSelection.length!=0"
                 @click="delSelect"
                 size="small">刪除勾選項</el-button>
    </el-row>
    <el-card>
      <el-table ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="55">
        </el-table-column>
        <el-table-column prop="name"
                         label="姓名"
                         width="120">
        </el-table-column>
        <el-table-column prop="email"
                         label="郵箱"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="type"
                         label="接收類型"
                         width="120">
          <template slot-scope="scope">
            {{checkOpinionType(scope.row.type)}}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="180px">
          <template slot-scope="scope">
            <el-button type="text"
                       size="mini"
                       @click="deleteInfo(scope.row)"
                       class="editBtn">刪除</el-button>
            <el-button type="text"
                       size="mini"
                       @click="editAddrDialog(scope.row)"
                       class="editBtn">編輯</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.page"
                     :page-sizes="[10, 20, 40, 50]"
                     :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
      <el-dialog title="新增郵箱"
                 :visible.sync="editDialogVisible"
                 width="50%"
                 @close="editAddrClosed">
        <el-form :model="editAddrForm"
                 :rules="editAddrFormRules"
                 ref="editAddrRef"
                 label-width="120px"
                 class="demo-ruleForm">
          <el-form-item label="姓名"
                        prop="name">
            <el-input v-model="editAddrForm.name"></el-input>
            </el-cascader>
          </el-form-item>
          <el-form-item label="郵箱"
                        prop="email">
            <el-input v-model="editAddrForm.email"></el-input>
          </el-form-item>
          <el-form-item label="接收類型"
                        prop="type">
            <el-select v-model="editAddrForm.type"
                       placeholder="请选择">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="editAddrClosed">取 消</el-button>
          <el-button type="danger"
                     @click="editOrderAddr">确 定</el-button>
        </span>
      </el-dialog>

    </el-card>
  </div>
</template>
<script>
import cityData from './citydata.js'
import { noticList, noticAdd, noticDel, noticBatchDel, noticEdit } from '@/config/index'
export default {
  data () {
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/

      if (regEmail.test(value)) {
        return callback()
      }
      callback(new Error('請輸入合法的郵箱格式'))
    }
    return {
      queryInfo: {
        query: '',
        page: 1,
        pageSize: 10
      },
      total: 0,
      orderList: [],
      editDialogVisible: false,
      editAddrForm: {
        name: '',
        email: '',
        type: 3

      },
      editAddrFormRules: {
        name: [
          {
            required: true, message: '請輸入姓名', trigger: 'blur'
          }],
        email: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur'
          }
        ],
        type: [{
          required: true, message: '請選擇', trigger: 'change'
        }]

      },
      addrProps: {
        value: '',
        label: '',
        children: ''
      },
      cityData: cityData,
      proDialogVisible: false,
      progressInfo: [],
      tableData: [],
      multipleSelection: [],
      options: [

        {
          value: 0,
          label: '停止接收'
        },
        {
          value: 1,
          label: '僅意見'
        }, {
          value: 2,
          label: '僅投訴',
          disabled: true
        }, {
          value: 3,
          label: '全部接收'
        }, {
          value: 4,
          label: '僅表揚'
        }

      ],
      noticId: ''

    }
  },
  computed: {
    checkOpinionType () {
      return (type) => {
        switch (type) {
          case 0:
            return '停止接收'
          case 1:
            return '僅投訴'
          case 2:
            return '僅意見'
          case 3:
            return '全部接收'
          case 4:
            return '僅表揚'
        }
      }
    }

  },
  created () {
    this.getOrderList()
  },
  methods: {
    async getOrderList () {
      noticList(this.queryInfo).then(res => {
        console.log('res', res.status)
        if (res.status == 200) {
          console.log(res.data)
          const { data } = res.data
          this.tableData = data?.items
          this.total = data.total
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getOrderList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage
      this.getOrderList()
    },
    editAddrDialog (row) {
      if (row.id) {
        this.editAddrForm = Object.assign({}, row)
        this.noticId = row.id
      }
      this.editDialogVisible = true
    },
    deleteGoods () {

    },
    editOrderAddr () {
      this.$refs.editAddrRef.validate(async valid => {
        if (valid) {
          if (this.noticId) {
            noticEdit(this.editAddrForm).then(res => {
              console.log('res', res.status)
              if (res.status == 200) {
                console.log(res.data)
                this.$message.success('編輯成功')
                this.getOrderList()
                this.editAddrClosed()
              }
            }).catch(err => {
              console.log('catch_err', err)
            })
          } else {
            noticAdd(this.editAddrForm).then(res => {
              console.log('res', res.status)
              if (res.status == 200) {
                console.log(res.data)
                this.$message.success('新增成功')
                this.getOrderList()
                this.editAddrClosed()
              }
            }).catch(err => {
              console.log('catch_err', err)
            })
          }
        }
      })
    },
    editAddrClosed () {
      console.log('editAddrClosed')
      this.editDialogVisible = false
      this.$refs.editAddrRef.resetFields()
      this.$set(this, 'editAddrForm', this.$options.data().editAddrForm)
    },
    handleChangeAddr () {

    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = []
      if (val.length != 0) {
        val.forEach(v => {
          this.multipleSelection.push(v.id)
        })
      }

      console.log('刪除id', this.multipleSelection)
    },
    deleteInfo (row) {
      const params = { id: row.id }
      noticDel(params).then(res => {
        if (res.status == 200) {
          this.$message.success('删除成功')
          this.getOrderList()
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    },
    delSelect () {
      noticBatchDel(this.multipleSelection).then(res => {
        if (res.status == 200) {
          this.$message.success('删除成功')
          this.getOrderList()
        }
      }).catch(err => {
        console.log('catch_err', err)
      })
    }
  }

}
</script>
<style lang="less" scoped>
</style>
