
import axios from 'axios'
import { baseUrl } from '@/.env'
import { Message } from 'element-ui'
import router from '@/router'
// import qs from 'qs'

// let apiBaseUrl="/api"
const apiBaseUrl = `${baseUrl}`
// let apiBaseUrl="https://devopinion.wechattips.com/api"

const service = axios.create({
    baseURL: apiBaseUrl,
    timeout: 9000// 请求超时时间
  })

service.interceptors.request.use(config => {
    const token = window.localStorage.getItem('userToken') || window.sessionStorage.getItem('userToken')
    const expTime = window.localStorage.getItem('expTime') || window.sessionStorage.getItem('expTime')
    const nowTime = new Date().getTime()
		if (expTime && nowTime > expTime) {
      window.localStorage.clear()
      window.sessionStorage.clear()
      Message.error('登錄過期，請重新登錄')
      router.push({ path: '/login', replace: true }).then(() => {})
      return config
    }
    // config.data=Object.assign({},config.data,{
    //     token:token
    // })
    config.headers = {
        'System-Authorization': window.sessionStorage.getItem('token')
    }
    return config
}, err => {
    return error
})

service.interceptors.response.use(response => {
    if (response.status) {
      switch (response.status) {
        case 200:
          // this.$message.success("请求成功")
          if (response.data.code == '0004') {
            window.localStorage.clear()
      window.sessionStorage.clear()
      Message.error('登錄過期，請重新登錄')
      router.push({ path: '/login', replace: true }).then(() => {})
          }
          return response
        case 401:
          Message.error('请进行登录')
          break
        case 403:
          Message.error('没有权限')
          // token过期处理方法
          break
        default:
          Message.error(response.data)
      }
    } else {
      return response
    }
  }, err => {
      console.log('err_response', err.response)
      if (err.response) {
      switch (err.response.status) {
        case 500:
          Message.error('服务器错误')
        break
        case 502:
          Message.error('网络错误')
        break
        case 504:
          Message.error('网络超时')
        break
        default:
          Message.error('接口錯誤')
          return Promise.reject(err)
      }
    } else {
       Message.error('接口錯誤')
       return Promise.reject(err)
    }
  })

const http = {
    post: '',
    get: ''
}

 http.post = function (api, data = {}, params = {}) {
     // let newData=qs.stringify(data)
    return new Promise((resolve, reject) => {
        service.post(api, data, { params: params }).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

http.get = function (api, params = {}) {
    // let newParams=qs.stringify(params)
    return new Promise((resolve, reject) => {
        service.get(api, { params: params }).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

http.delete = function (api, params = {}) {
  return new Promise((resolve, reject) => {
      service.delete(api, { params: params }).then((res) => {
          resolve(res)
      }).catch(err => {
          reject(err)
      })
  })
}

  export default http
