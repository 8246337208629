<template>
  <el-container class="home-container">
    <el-aside :width="isCollapse ? '64px' : '200px'"
              class="el-aside-menu">
      <div class="menuLeftLogo">
        <!-- <img src="../assets/logo.jpg"> -->
        <span v-if="!isCollapse">意見反饋系統</span>
      </div>
      <div class="toggleMenu"
           @click="toggleCollapse">
        <!-- <i class="el-icon-more"></i> -->
        {{ isCollapse?'展開': '收起' }}
      </div>
      <el-menu unique-opened
               router
               :default-active="$route.path"
               :collapse="isCollapse"
               :collapse-transition="false"
               class="el-menu-vertical-demo"
               @open="handleOpen"
               @close="handleClose"
               background-color="#313743"
               text-color="#fff"
               active-text-color="#409EFF">
        <section v-for="(item, index) in menuList"
                 :key="item.id">
          <!-- 一级菜单 -->
          <el-submenu v-if="item.children.length != 0"
                      :index="item.id + ''">
            <template slot="title">
              <!-- <i :class="iconsObj[item.id]"></i> -->
              <i class="el-icon-menu"></i>
              <span v-if="!isCollapse">{{ item.authName }}</span>
            </template>
            <!-- 二级菜单-->
            <el-menu-item :index="'/' + menuItem.path"
                          v-for="(menuItem, menuIndex) in item.children"
                          :key="menuItem.id"
                          @click="saveNsvState('/' + menuItem.path)">
              <template slot="title">
                <!-- <i class="el-icon-menu"></i> -->
                <span>{{ menuItem.authName }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-else
                        :index="'/' + item.path"
                        @click="saveNsvState('/' + item.path)">
            <i class="el-icon-menu"></i>
            <span slot="title">{{ item.authName }}</span>
          </el-menu-item>
        </section>

      </el-menu>
      <div class="versionCode">{{ this.currentVersion }}</div>

    </el-aside>
    <el-container class="center-container">
      <el-header>
        <div class="headerLeft">
          <!-- <img src="../assets/logo.jpg"> -->
          <span>{{ $route.name }}</span>
        </div>
        <div class="login-info"
             @mouseenter="logoutChangeVisible"
             @mouseleave="logoutChangeHide">
          <div class="login-img"></div>
          <div class="user-info">{{loginName}}</div>
        </div>
        <div class="logout-menu"
             v-if="logoutVisble"
             @mouseenter="logoutChangeVisible"
             @mouseleave="logoutChangeHide">
          <p @click="editUserInfo">編輯賬號</p>
          <p class="logout-text"
             @click="logout">退出登錄</p>
        </div>
        <!-- <el-button type="info" @click="logout">退出</el-button> -->
      </el-header>
      <el-main>
        <router-view></router-view>
        <el-dialog title="編輯賬戶"
                   :visible.sync="editDialogVisible"
                   width="30%"
                   :before-close="handleDiologClose"
                   @close="editDialogClosed">
          <el-form :model="editUserForm"
                   :rules="addUserRules"
                   ref="editFormRef"
                   label-width="70px"
                   class="demo-ruleForm">
            <el-form-item label="用户名">
              <el-input v-model="editUserForm.username"></el-input>
            </el-form-item>
            <el-form-item label="密碼"
                          prop="password">
              <el-input v-model="editUserForm.password"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer"
                class="dialog-footer">
            <el-button @click="editDialogVisible = false">取 消</el-button>
            <el-button type="primary"
                       @click="updateUser">确 定</el-button>
          </span>
        </el-dialog>
      </el-main>

    </el-container>
  </el-container>
</template>

<script>
import { currentVersion } from '@/.env'
import { logoutText } from '@/config/index'
export default {
  data () {
    return {
      menuList: [
        {
          id: 0,
          authName: '工單管理',
          path: 'goods',
          children: []
        },
        {
          id: 1,
          authName: '分類管理',
          children: [
            {
              id: 101,
              authName: '分類類表',
              path: 'categories'

            },
            {
              id: 102,
              authName: '新增/編輯分類',
              path: 'categories/edit'

            }
          ]
        },
        {
          id: 2,
          authName: '數據統計',
          path: 'reports',
          children: []
        },
        {
          id: 3,
          authName: '賬號管理',
          children: [
            {
              id: 101,
              authName: '賬戶列表',
              path: 'users'

            },
            {
              id: 102,
              authName: '新增/編輯賬號',
              path: 'users/edit'

            }
          ]
        },
        {
          id: 4,
          authName: '通知管理',
          path: 'orders',
          children: []
        }
      ],
      iconsObj: {
        125: 'iconfont icon-yonghutouxiang',
        103: 'iconfont icon-tianchongxing-',
        101: 'iconfont icon-gongwenbao',
        102: 'iconfont icon-danju-tianchong',
        145: 'iconfont icon-baobiao'
      },
      isCollapse: false,
      activePath: '',
      logoutVisble: false,
      editDialogVisible: false,
      editUserForm: {
        username: '',
        password: ''
      },
      addUserRules: {
        name: [
          { required: true, message: '請輸入分類名稱', trigger: 'blur' },
          { min: 3, max: 12, message: '长度在 3 到 12 个字符', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '請選擇所屬分類', trigger: 'change' }
        ],
        iconUrl: [
          { required: false, message: '請上傳分類圖片', trigger: 'change' }
        ]
      }

    }
  },
  computed: {
    loginName () {
      const { username } = JSON.parse(window.sessionStorage.getItem('userInfo'))
      return username
    },
    currentVersion () {
      return currentVersion
    }
  },
  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    // this.getMenuList();
    console.log('store', this.$store.state)
  },
  methods: {
    async logout () {
      try {
        const res = await logoutText()
        if (res.status == 200) {
          window.sessionStorage.clear()
        } else {
          throw new Error(res.msg)
        }
      } catch (error) {
        console.error('logout', error)
      }
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    async getMenuList () {
      const { data: res } = await this.$http.get('menus')
      if (res.meta.status !== 200) {
        return this.$message.error(res.meta.msg)
      } else {
        this.menuList = res.data
      }
    },
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },
    saveNsvState (activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath

      const pathObj = {
        activePath: activePath,
        name: this.$route.name
      }
      this.$store.commit('changePath', pathObj)
      // console.log('0203', this.$store.state.routerName)
    },
    logoutChangeVisible () {
      this.logoutVisble = true
    },
    logoutChangeHide () {
      this.logoutVisble = false
    },
    updateUser () {
      this.editDialogVisible = false
    },
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    handleDiologClose (done) {
      this.editDialogVisible = false
    },
    editUserInfo () {
      const row = JSON.parse(window.sessionStorage.getItem('userInfo'))
      this.$router.push({ path: '/mine', query: { row: row, type: 'mine' } })
    }

  }
}
</script>

<style lang="less" scoped>
.iconfont {
  margin-right: 10px;
}

.home-container {
  width: 100%;
  height: 100%;
  font-family: PingFang SC;
  .el-aside-menu {
    position: relative;
    .versionCode {
      position: absolute;
      bottom: 16px;
      left: 50%;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      letter-spacing: 0.75px;
      transform: translateX(-50%);
    }
  }

  .center-container {
    width: 100%;

    .el-header {
      width: 100%;
      //background-color: #363d3f;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      padding-left: 0;
      align-items: center;
      color: #fff;
      font-size: 20px;
      position: relative;

      .logout-menu {
        position: absolute;
        right: 0;
        top: 60px;
        width: 130px;
        height: 96px;
        z-index: 999;
        background: #ffffff;
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.11);
        text-align: center;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 22px;
          margin: 5px;
          cursor: pointer;
        }

        .logout-text {
          width: 100%;
          color: #df0000;
          // cursor: pointer;
        }
      }

      .headerLeft {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
        position: relative;

        img {
          width: 50px;
          height: 100%;
        }

        span {
          margin-left: 15px;
        }
      }

      .login-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191919;
        line-height: 20px;
        position: relative;
        height: 100px;

        .login-img {
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
          background-image: url("../assets/img/headimg.png");
          margin-right: 10px;
        }
      }
    }
  }
}

.home-container .el-aside {
  background-color: #313743;

  .el-menu {
    border-right: none;
  }

  .menuLeftLogo {
    width: 202px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.85);
  }
}

.el-main {
  background-color: #e9edf0;
}

.toggleMenu {
  background: #475162;
  text-align: center;
  font-size: 12px;
  line-height: 28px;
  color: #fff;
  cursor: pointer;
}
</style>
